import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';

import { getCoords } from '../filters/selectors';

import { getLocalStorage } from '../../shared/utils/getLocalStorage';

import {
  fetchExperiences,
  fetchExperiencesSuccess,
  fetchExperiencesFailure,
  updatePage,
  updateFilters,
  updateRandomizationSeed
} from './actions';

import { updatePluginFilters } from '../plugins/actions';

import { getPage, getFilters, getRandomizationSeed, getI18n } from './selectors';
import { getPluginId, isLocationFilterEnabled } from '../plugins/selectors';
import { getQueryParams } from '../router/selectors';

import Api from './api';

function collectTruthyKeys(obj) {
  return Object.keys(obj).filter(key => obj[key]);
}

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  let equal = true;
  keys1.forEach(key => {
    // eslint-disable-next-line eqeqeq
    if (object1[key] != object2[key]) {
      equal = false;
    }
  });

  return equal;
}

function* updateQueryParams(newParams) {
  const queryParams = yield select(getQueryParams);
  let newQueryParams = { ...queryParams, ...newParams };

  // Remove null entries from object - https://stackoverflow.com/a/38340730
  newQueryParams = Object.fromEntries(Object.entries(newQueryParams).filter(([, v]) => v != null));

  yield put(
    push({
      search: `?${queryString.stringify(newQueryParams, { arrayFormat: 'comma' })}`
    })
  );

  return newQueryParams;
}

function* getFiltersWithLocation() {
  const locationFilterEnabled = yield select(isLocationFilterEnabled);
  const filters = yield select(getFilters);

  if (locationFilterEnabled) {
    const { locationQuery } = filters;
    if (locationQuery) return filters;

    const storedLocationQuery = getLocalStorage().getItem('locationQuery');
    const storedLocationRadius = getLocalStorage().getItem('locationRadius');

    if (storedLocationQuery && storedLocationRadius) {
      return {
        ...filters,
        locationQuery: storedLocationQuery,
        locationRadius: storedLocationRadius
      };
    }

    const coords = yield select(getCoords);
    if (coords) return { ...filters, locationQuery: coords };
  } else {
    return filters;
  }
}

function* fetchExperiencesSaga() {
  try {
    const pluginId = yield select(getPluginId);
    const page = yield select(getPage);
    const i18n = yield select(getI18n);
    const randomizationSeed = yield select(getRandomizationSeed);

    const filters = yield getFiltersWithLocation();

    const params = {
      pluginId,
      page,
      randomizationSeed,
      filters,
      i18n
    };

    const response = yield call(Api.fetchExperiences, params);
    const { totalPages, locationQuery, locationRadius, categories } = response.data;

    const experiences = response.data.experiences.data;

    if (locationQuery && locationRadius) {
      yield updateQueryParams({ locationQuery, locationRadius });
      // Set location info in localStorage, so that when hitting the back button back to
      // the experience list view in an iframe, we are able to save this user selected info,
      // since we can't rely on the URL.
      getLocalStorage().setItem('locationQuery', locationQuery);
      getLocalStorage().setItem('locationRadius', locationRadius);
    }

    yield put(
      fetchExperiencesSuccess({ experiences, page, totalPages, locationQuery, locationRadius })
    );

    if (categories?.length) {
      yield put(updatePluginFilters({ categories }));
    }
  } catch (error) {
    yield put(fetchExperiencesFailure({ error }));
  }
}

function* updatePageSaga({ payload: { page } }) {
  try {
    // If the page is changed, we should scroll to the top of the page
    if ('parentIFrame' in window) {
      // https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md#scrolltoxy
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    yield updateQueryParams({ page });
    yield put(fetchExperiences());
  } catch (error) {
    console.error(error);
  }
}

function* updateFiltersSaga({ payload }) {
  try {
    let modifiedPayload = payload;
    const { categories } = payload;
    if (categories) {
      modifiedPayload = { ...modifiedPayload, categories: collectTruthyKeys(categories) };
    }

    const queryParams = yield select(getQueryParams);

    // If a filter is changed, we need to set the page back to 1
    const newQueryParams = yield updateQueryParams({ ...modifiedPayload, page: 1 });
    // Only update if needed to avoid triggering fetchExperiences
    if (!shallowEqual(queryParams, newQueryParams)) {
      yield put(fetchExperiences());
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateRandomizationSeedSaga() {
  try {
    // 1. See if randomization seed already exists
    let randomizationSeed = yield select(getRandomizationSeed);
    const locationFilterEnabled = yield select(isLocationFilterEnabled);

    // 2. If not, create a new one (But don't create if location filter is enabled)
    if (!randomizationSeed && !locationFilterEnabled) {
      randomizationSeed = Math.random().toFixed(1);
      yield updateQueryParams({ randomizationSeed });
    }

    yield put(fetchExperiences());
  } catch (error) {
    console.error(error);
  }
}

export const sagas = {
  listenForFetchExperiencesSaga: takeLatest(fetchExperiences.type, fetchExperiencesSaga),
  listenForUpdatePageSaga: takeLatest(updatePage.type, updatePageSaga),
  listenForUpdateFiltersSaga: takeLatest(updateFilters.type, updateFiltersSaga),
  listenForUpdateRandomizationSeedSaga: takeLatest(
    updateRandomizationSeed.type,
    updateRandomizationSeedSaga
  )
};
