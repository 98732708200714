import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

import { updateFilters } from '../../../redux/experiences/actions';
import { getFilters, getMobileFiltersAppliedCount } from '../../../redux/experiences/selectors';

import { setSelectedLocation, setCurrentFilter } from '../../../redux/filters/actions';
import { getCurrentFilters } from '../../../redux/filters/selectors';

import {
  getPlugin,
  isDateFilterEnabled,
  isGuestFilterEnabled,
  isLocationFilterEnabled,
  getAllCategories
} from '../../../redux/plugins/selectors';

import Modal from '../../../shared/components/Modal';
import { filterButton } from '../../../shared/utils/staticCssClasses';
import DateFilter from '../filters/DateFilter';
import GuestFilter from '../filters/GuestFilter';
import LocationFilter from '../filters/LocationFilter';
import { CategoryFilter } from '../filters/CategoryFilter';

import {
  FilterButton,
  HeaderBar,
  FooterBar,
  ThinLink,
  modalCss,
  OutlineButton
} from '../filters/filterStyles';

const MobileFilterButton = styled(FilterButton)`
  margin-right: 0px;
`;

const MobileContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;
`;

export const FiltersMobile = ({ toggleFadeList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { useKmForDistance } = useSelector(getPlugin);
  const currentFilters = useSelector(getCurrentFilters);

  const dateFilterEnabled = useSelector(isDateFilterEnabled);
  const guestFilterEnabled = useSelector(isGuestFilterEnabled);
  const locationFilterEnabled = useSelector(isLocationFilterEnabled);
  const allCategories = useSelector(getAllCategories);

  const mobileFiltersAppliedCount = useSelector(getMobileFiltersAppliedCount);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const filters = useSelector(getFilters);
  const { guestCount, startDate, endDate, locationQuery } = filters;
  const {
    currentLocationQuery,
    currentLocationRadius,
    selectedLocationQuery,
    selectedCategoriesMap
  } = currentFilters;

  const mobileFilterApplied = mobileFiltersAppliedCount > 0;

  // Internal state to store the state of the filters in the mobile modal
  const [iStartDate, setIStartDate] = useState(startDate);
  const [iEndDate, setIEndDate] = useState(endDate);
  const [iGuestCount, setIGuestCount] = useState(guestCount);

  const [cacheKey, setCacheKey] = useState(0);
  const updateCacheKey = () => setCacheKey(cacheKey + 1);

  const tippyInstances = {
    location: useRef()
  };

  const tippyProps = tippyName => ({
    onCreate: instance => {
      tippyInstances[tippyName].current = instance;
    },
    onShow: () => toggleFadeList(true),
    onHide: () => toggleFadeList(false),
    onHidden: () => updateCacheKey()
  });

  useEffect(() => {
    if (window.parentIFrame) {
      const rootElement = document.getElementById('root');

      window.parentIFrame.getPageInfo(({ windowHeight }) => {
        if (mobileFiltersOpen) {
          rootElement.style.height = `${windowHeight - 110}px`;
        } else {
          rootElement.style.height = 'unset';
        }
      });
    }
  }, [mobileFiltersOpen]);

  const toggleModal = e => {
    setMobileFiltersOpen(!mobileFiltersOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  const closeModal = () => {
    setMobileFiltersOpen(false);
  };

  const applyAllFilters = () => {
    dispatch(
      updateFilters({
        startDate: iStartDate,
        endDate: iEndDate,
        guestCount: iGuestCount,
        categories: selectedCategoriesMap
      })
    );
    closeModal();
  };

  const clearAllFilters = () => {
    dispatch(
      updateFilters({
        startDate: null,
        endDate: null,
        guestCount: null,
        categories: null
      })
    );
    setIStartDate(null);
    setIEndDate(null);
    setIGuestCount(null);
    closeModal();
  };

  const applyDisabled =
    (selectedLocationQuery && !currentLocationRadius) ||
    (currentLocationRadius && !selectedLocationQuery);

  const showFilterButton = dateFilterEnabled || guestFilterEnabled || allCategories.length > 0;

  if (!showFilterButton && !locationFilterEnabled) {
    return null;
  }

  return (
    <MobileContainer>
      {locationFilterEnabled && (
        <LocationFilter
          address={currentLocationQuery}
          radius={currentLocationRadius}
          setAddress={location => dispatch(setCurrentFilter({ location }))}
          setRadius={radius => dispatch(setCurrentFilter({ radius }))}
          applied={locationQuery}
          popoverProps={tippyProps('location')}
          buttonLabel={locationQuery}
          selectedLocation={selectedLocationQuery}
          setSelectedLocation={location => dispatch(setSelectedLocation({ location }))}
          useKmForDistance={useKmForDistance}
          applyFilter={() => {
            dispatch(
              updateFilters({
                locationQuery: currentLocationQuery,
                locationRadius: currentLocationRadius
              })
            );
            tippyInstances.location.current.hide();
          }}
        />
      )}
      {showFilterButton && (
        <MobileFilterButton
          applied={mobileFilterApplied}
          className={filterButton}
          onClick={toggleModal}
          onTouchEnd={toggleModal}
          data-testid="mobile-filter-button"
        >
          {t('filters')}
          {mobileFilterApplied ? `: ${mobileFiltersAppliedCount}` : null}
        </MobileFilterButton>
      )}
      <Modal hideClose slideUp open={mobileFiltersOpen} close={closeModal} css={modalCss}>
        <HeaderBar>
          <ThinLink
            link
            align="left"
            onTouchEnd={closeModal}
            onClick={closeModal}
            data-testid="cancel-filter"
          >
            {t('cancel')}
          </ThinLink>
          <ThinLink bold>{t('filters')}</ThinLink>
          <OutlineButton
            disabled={applyDisabled}
            onTouchEnd={applyAllFilters}
            onClick={applyAllFilters}
          >
            {t('apply')}
          </OutlineButton>
        </HeaderBar>
        {dateFilterEnabled && (
          <DateFilter
            mobile
            applyFilter={({ startDate: newStartDate, endDate: newEndDate }) => {
              setIStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : null);
              setIEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : null);
            }}
            startDate={iStartDate}
            endDate={iEndDate}
          />
        )}
        {guestFilterEnabled && (
          <GuestFilter
            mobile
            applyFilter={newGuestCount => setIGuestCount(newGuestCount)}
            guestCount={iGuestCount}
          />
        )}
        {allCategories.length > 0 && <CategoryFilter mobile allCategories={allCategories} />}

        <FooterBar>
          <ThinLink
            link
            align="left"
            onTouchEnd={clearAllFilters}
            onClick={clearAllFilters}
            data-testid="mobile-clear-filter"
          >
            {t('clear')}
          </ThinLink>
          <OutlineButton
            disabled={applyDisabled}
            onTouchEnd={applyAllFilters}
            onClick={applyAllFilters}
            data-testid="mobile-apply-filter"
          >
            {t('apply')}
          </OutlineButton>
          <div style={{ width: '58px' }} />
        </FooterBar>
      </Modal>
    </MobileContainer>
  );
};
