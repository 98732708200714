import { createReducer } from '@reduxjs/toolkit';
import queryString from 'query-string';

import {
  fetchExperiencesSuccess,
  fetchExperiencesFailure,
  updateFilters
} from '../experiences/actions';

import {
  geolocatingComplete,
  clearCurrentFilters,
  setSelectedLocation,
  setCurrentFilter
} from './actions';

const initialState = search => {
  const { locationQuery, locationRadius, categories } = queryString.parse(search);

  return {
    isGeolocating: true,
    coords: null,
    currentLocationQuery: locationQuery,
    selectedLocationQuery: locationQuery,
    currentLocationRadius: locationRadius ? Number(locationRadius) : null,
    selectedCategoriesMap: categories
      ? categories.split(',').reduce((obj, cat) => ({ ...obj, [cat]: true }), {})
      : {}
  };
};

export const reducer = history => {
  return createReducer(initialState(history.location.search), {
    [geolocatingComplete]: (state, { payload }) => {
      state.isGeolocating = false;
      if (payload?.coords) state.coords = payload.coords;
    },
    [setSelectedLocation]: (state, { payload: { location } }) => {
      state.selectedLocationQuery = location;
    },
    [clearCurrentFilters]: state => {
      state.currentLocationQuery = null;
      state.selectedLocationQuery = null;
      state.currentLocationRadius = null;
    },
    [setCurrentFilter]: (state, { payload }) => {
      const { location, radius, categories } = payload;
      if (location != null) state.currentLocationQuery = location;
      if (radius) state.currentLocationRadius = radius;
      if (categories) state.selectedCategoriesMap = categories;
    },
    [fetchExperiencesSuccess]: (state, { payload: { locationQuery, locationRadius } }) => {
      state.coords = null;
      state.currentLocationQuery = locationQuery;
      state.selectedLocationQuery = locationQuery;
      state.currentLocationRadius = locationRadius;
    },
    [fetchExperiencesFailure]: state => {
      state.coords = null;
    },
    [updateFilters]: (state, { payload }) => {
      const { categories } = payload;
      if (categories === null) state.selectedCategoriesMap = {};
    }
  });
};
