/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { filterButton } from '../../../shared/utils/staticCssClasses';
import Actions from './Actions';
import Datepicker from '../../../shared/components/form/Datepicker';
import Popover from '../../../shared/components/Popover';

import {
  FilterButton,
  PopoverBody,
  filterContainerCss,
  filterHeaderCss,
  hideDesktopCss
} from './filterStyles';

const Container = styled.div`
  width: 450px;
  .DateRangePickerInput {
    grid-template-columns: 140px 110px 150px 1fr;
  }

  @media (max-width: 767px) {
    .DateRangePickerInput {
      grid-template-columns: 1fr 0.5fr 1fr 0.1fr;
    }
    .DayPicker_focusRegion {
      margin-top: 75px;
    }

    .DateInput_input {
      font-size: 14px;
      line-height: 14px;
      padding: 9px 9px 8px;
    }
  }

  ${filterContainerCss};
`;

const Heading = styled.div``;

const DatepickerComponent = styled(Datepicker)`
  padding-top: 5px;
`;

const DateFilter = ({
  mobile,
  applied,
  popoverProps,
  buttonLabel,
  applyFilter,
  clearFilter,
  startDate,
  endDate,
  cacheKey
}) => {
  const { t } = useTranslation();

  const [iStartDate, setIStartDate] = useState(startDate ? moment(startDate) : null);
  const [iEndDate, setIEndDate] = useState(endDate ? moment(endDate) : null);

  useEffect(() => {
    setIStartDate(startDate ? moment(startDate) : null);
    setIEndDate(endDate ? moment(endDate) : null);
  }, [startDate, endDate, cacheKey]);

  const updateInternalDates = ({ startDate: newStartDate, endDate: newEndDate }) => {
    const newMomentStartDate = moment(newStartDate);
    const newMomentEndDate = moment(newEndDate);

    if (newStartDate) setIStartDate(newMomentStartDate);
    if (newEndDate) setIEndDate(newMomentEndDate);
    if (mobile) {
      applyFilter({ startDate: newMomentStartDate, endDate: newMomentEndDate });
    }
  };

  const filter = (
    <Container data-testid="date-filter-widget">
      <Heading css={[filterHeaderCss, hideDesktopCss]}>{t('datesAvailable')}</Heading>
      <DatepickerComponent
        startDate={iStartDate}
        endDate={iEndDate}
        updateDates={updateInternalDates}
        mobile={mobile}
      />
      {mobile ? null : (
        <Actions
          apply={() => applyFilter({ startDate: iStartDate, endDate: iEndDate })}
          clear={clearFilter}
        />
      )}
    </Container>
  );

  if (mobile) return filter;
  return (
    <Popover {...popoverProps} content={<PopoverBody>{filter}</PopoverBody>}>
      <FilterButton className={filterButton} data-testid="date-filter" applied={applied}>
        {applied ? buttonLabel : t('dates')}
      </FilterButton>
    </Popover>
  );
};
export default DateFilter;
