import React from 'react';
import { useSelector } from 'react-redux';

import { getPluginType } from '../redux/plugins/selectors';
import { ExperienceList } from '../experience_list/ExperienceList';

export const PluginRenderer = () => {
  const pluginType = useSelector(getPluginType);

  if (pluginType === 'experience_list') {
    return <ExperienceList />;
  }
  return <>Unsupported plugin type</>;
};
