import React from 'react';
import { Global, css } from '@emotion/react';
import Tippy from '@tippy.js/react';
import 'tippy.js/themes/light-border.css';

const globalStyles = (
  <Global
    styles={css`
      .tippy-tooltip {
        padding: 0;
      }
    `}
  />
);

const styleOptions = {
  theme: 'light-border',
  arrow: true,
  maxWidth: 600,
  animation: 'shift-away',
  duration: [200, 150],
  trigger: 'click',
  interactive: true
};

const Popover = props => {
  const { children, ...otherProps } = props;

  return (
    <>
      {globalStyles}
      <Tippy {...styleOptions} {...otherProps}>
        {children}
      </Tippy>
    </>
  );
};

export default Popover;
