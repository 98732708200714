import React from 'react';

export default function ArrowIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 10.5 10.9"
      style={{ enableBackground: 'new 0 0 10.5 10.9' }}
      xmlSpace="preserve"
      {...props}
    >
      <polygon
        id="XMLID_12_"
        points="9.8,4.9 5.8,8.9 5.8,0 4.8,0 4.8,8.9 0.7,4.9 0,5.6 5.3,10.9 10.5,5.6 "
      />
    </svg>
  );
}
