const itIT = {
  translation: {
    apply: 'Applica',
    apr: 'apr.',
    aug: 'ag.',
    bookNow: 'Prenota ora',
    cancel: 'Annulla',
    categories: 'Categorie',
    category: 'Categoria',
    chinese_simplified: 'Cinese Semplificato',
    chinese_traditional: 'Cinese Tradizionale',
    clear: 'Chiaro',
    dateAbbreviated: '{{day}} {{month}}',
    dates: 'Date',
    datesAvailable: 'Date Disponibili',
    day: 'giorno',
    days: 'giorni',
    dec: 'dic.',
    dutch: 'Olandese',
    enterALocation: 'Inserisci una località',
    feb: 'febbr.',
    filters: 'Filtri',
    fromPrice: 'Da',
    fromTime: 'Da',
    german: 'Tedesco',
    greek: 'Greco',
    guest: 'Ospite',
    guests: 'Ospiti',
    hour: 'ora',
    hours: 'ore',
    jan: 'genn.',
    jul: 'luglio',
    jun: 'giugno',
    kilometers: 'Chilometri',
    location: 'Luogo',
    mar: 'mar.',
    may: 'magg.',
    miles: 'chilometri',
    minutes: 'minuti',
    more: 'altro',
    next: 'Avanti',
    noFilterResults: 'Siamo spiacenti, non abbiamo trovato risultati per i filtri selezionati.',
    noResults: 'Purtroppo non abbiamo trovato nessuna esperienza.',
    noResultsDates: 'Siamo spiacenti, non abbiamo trovato risultati per le date selezionate.',
    noResultsFuture: 'Siamo spiacenti, al momento non abbiamo esperienze disponibili.',
    noResultsGuests:
      'Siamo spiacenti, non abbiamo trovato risultati per il numero selezionato di ospiti.',
    noResultsWithin:
      'Spiacenti, non abbiamo trovato risultati entro <strong>{{distance}} {{units}}</strong> da <strong>{{location}}.</strong>',
    nov: 'nov.',
    numberOfGuests: 'Numero di ospiti',
    oct: 'ott.',
    online: 'In linea',
    previous: 'Previous',
    search: 'Cerca',
    select: 'Seleziona',
    sep: 'sett.',
    to: 'a',
    widenSearch: 'Prova ad ampliare la ricerca modificando o cancellando i filtri.',
    within: 'Entro'
  }
};

export default itIT;
