import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const baseUrl = process.env.REACT_APP_ANYROAD_API_URL;

const Api = {
  fetchExperiences({ pluginId, page, randomizationSeed, filters, i18n }) {
    return axios({
      headers,
      method: 'get',
      url: `${baseUrl}/plugins/api/v3/experiences`,
      params: {
        plugin_id: pluginId,
        page,
        randomizationSeed,
        i18n,
        ...filters
      }
    });
  }
};

export default Api;
