import { createReducer } from '@reduxjs/toolkit';

import { fetchExperiences, fetchExperiencesSuccess, fetchExperiencesFailure } from './actions';

export const initialState = {
  experiences: [],
  experiencesFetching: true,
  experiencesFetchingError: false,
  page: 1,
  totalPages: 1
};

export const reducer = createReducer(initialState, {
  [fetchExperiences]: state => {
    state.experiencesFetching = true;
    state.experiencesFetchingError = false;
  },
  [fetchExperiencesSuccess]: (state, { payload: { experiences, page, totalPages } }) => {
    state.experiences = experiences;
    state.experiencesFetching = false;
    state.experiencesFetchingError = false;
    state.page = page;
    state.totalPages = totalPages;
  },
  [fetchExperiencesFailure]: state => {
    state.experiences = [];
    state.experiencesFetching = false;
    state.experiencesFetchingError = true;
  }
});
