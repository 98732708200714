import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { reducer as pluginReducer } from './plugins/reducer';
import { sagas as pluginSagas } from './plugins/sagas';

import { reducer as experiencesReducer } from './experiences/reducer';
import { sagas as experiencesSagas } from './experiences/sagas';

import { reducer as filtersReducer } from './filters/reducer';

export const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    plugins: pluginReducer,
    experiences: experiencesReducer,
    filters: filtersReducer(history)
  });

function* rootSaga() {
  yield all({
    ...pluginSagas,
    ...experiencesSagas
  });
}

const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? devTools({ name: 'AnyRoad - Plugins' }) : compose;

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

export const store = createStore(
  rootReducer(history), // root reducer with router state
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
