import Select from 'react-select';
import styled from '@emotion/styled/macro';
import { useTheme } from '@emotion/react';
import Color from 'color';
import { Theme } from '../../styles';

const StyledDropdownIndicator = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 5.5px 0 5.5px;
  border-color: ${Theme.colors.icon} transparent transparent transparent;
  margin-right: 10px;
`;

const DropdownIndicator = () => <StyledDropdownIndicator />;

const SelectComponent = props => {
  const { calculateOpacities, height, boldSelected } = props;
  const theme = useTheme();

  // React-Select custom style options
  // https://react-select.com/styles
  const customStyles = {
    control: provided => ({
      ...provided,
      height: height || '47px',
      cursor: 'pointer'
    }),
    menuList: () => ({
      paddingTop: 0,
      paddingBottom: 0
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected || state.isFocused ? 'white' : 'inherit',
      fontWeight: state.isSelected && boldSelected ? 'bold' : 'inherit',
      backgroundColor: state.isSelected || state.isFocused ? theme.brandColor : 'inherit',
      cursor: 'pointer'
    })
  };

  // Use the main partner brand color and convert into a few related colors
  const brandColor = Color(Theme.colors.inputs.border);
  const customColors = {
    primary: Theme.colors.inputs.border,
    primary75: calculateOpacities ? brandColor.lighten(0.25).string() : theme.brandColor,
    primary50: calculateOpacities ? brandColor.lighten(0.5).string() : theme.brandColor,
    primary25: calculateOpacities ? brandColor.lighten(0.75).string() : theme.brandColor
  };

  return (
    <Select
      onBlur={event => event.preventDefault()}
      components={{ DropdownIndicator }}
      {...props}
      styles={customStyles}
      theme={baseTheme => ({
        ...baseTheme,
        borderRadius: 0,
        colors: {
          ...baseTheme.colors,
          ...customColors
        }
      })}
    />
  );
};

export default SelectComponent;
