/*
  Renders a single experience card in the ExperienceList
*/
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import {
  bookExperienceButton,
  bookTourDetails,
  experienceCard,
  experienceDescription,
  experienceTitleAndDescription
} from '../../../shared/utils/staticCssClasses';
import ContentLoader from '../../../shared/components/ContentLoader';
import { Grid, Span, cssWordBreak, HR } from '../../../shared/styles';
import { fireProductClick } from '../../../shared/utils/gtmTracking';

import { Picture } from './components/Picture';
import { Header } from './components/Header';
import { Schedule } from './components/Schedule';
import { Time } from './components/Time';
import { Price } from './components/Price';
import { smallCssBreak, midCssBreak } from '../../common_styles';

const CardWrapper = styled.div``;

const Card = styled.a`
  border: solid 1px #cecece;
  padding: 15px;
  display: block;
  margin-bottom: 30px;
  color: #333;
  opacity: ${props => (props.dimmed ? '.4' : '1')};
  transition: opacity 200ms linear;
  position: relative;
  &:hover {
    color: black;
    text-decoration: none;
  }
  @media (max-width: ${smallCssBreak}) {
    padding: 0;
    margin-bottom: 120px;
  }
`;

const CardGrid = styled(Grid)`
  grid-gap: 15px;
  @media (max-width: ${midCssBreak}) {
    grid-template-columns: 210px 1fr;
    /* grid-template-rows: 210px; */
  }
  @media (max-width: ${smallCssBreak}) {
    grid-gap: 15px;
    grid-template-columns: 100%;
    grid-template-rows: ${props => (props.loading ? '210px' : 'auto')};
  }
`;

const Info = styled(Grid)`
  @media (max-width: ${midCssBreak}) {
    grid-template-rows: 1fr 36px;
  }
  @media (max-width: ${smallCssBreak}) {
    ${props => props.loading && 'grid-template-rows: 100%'};
  }
`;

const Description = styled.div`
  margin: 15px 0 0;
  font-weight: 300;
  line-height: 19px;
  ${cssWordBreak} p {
    ${cssWordBreak};
  }
  @media (max-width: ${midCssBreak}) {
    margin-top: 8px;
  }
  @media (max-width: 700px) {
    font-size: 12px;
  }
  @media (max-width: ${smallCssBreak}) {
    display: none;
  }
`;

const InfoFooter = styled(Grid)`
  align-items: center;
  grid-column-gap: 5px;

  @media (max-width: ${midCssBreak}) {
    grid-template-columns: 30% 1fr 1fr;
    grid-column-gap: 0px;
  }
  @media (max-width: ${smallCssBreak}) {
    grid-template-columns: 100%;
  }
`;

const LoadingFooter = styled(Grid)`
  @media (max-width: ${midCssBreak}) {
    grid-template-columns: 170px 1fr 100px;
  }
  @media (max-width: ${smallCssBreak}) {
    display: none;
  }
`;

const InfoMain = styled.div`
  padding: 10px 0 15px;
  @media (max-width: ${midCssBreak}) {
    padding-top: 2px;
  }
  @media (max-width: ${smallCssBreak}) {
    padding: 0 10px 10px;
    ${props => props.loading && 'padding-top: 40px'};
  }
`;

const ExperienceButton = styled(Span)`
  width: unset;
  min-width: 96px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: ${props => props.theme.brandColor};
  color: white;
  border: none;
  text-align: center;
  @media (max-width: ${midCssBreak}) {
    min-width: 96px;
    padding: 9px 10px;
    height: 36px;
  }
  @media (max-width: ${smallCssBreak}) {
    width: 250px;
    margin: 10px -1px;
    border-radius: 0;
    outline: none;
    padding: 9px 10px;
    font-size: 14px;
  }
`;

const Loader = styled(ContentLoader)`
  display: block;
  @media (max-width: ${smallCssBreak}) {
    ${props => props.hideOnMobile && 'display: none;'};
  }
`;

const PictureLoader = styled.div`
  width: 250px;
  height: 250px;

  @media (max-width: ${midCssBreak}) {
    width: 210px;
    height: 210px;
  }
  @media (max-width: ${smallCssBreak}) {
    width: 248px;
    height: 250px;
  }
`;

const ExperienceCard = ({
  loading,
  experience,
  bookNowNewTab,
  index,
  trackProductClick,
  useLocationAsSubtitle,
  isGa4Enabled,
  pluginId,
  pluginName
}) => {
  const isMobile = useMediaQuery({ maxWidth: smallCssBreak });

  if (loading) return <ExperienceCardLoading />;

  const {
    url,
    picture,
    nameTranslation,
    taglineTranslation,
    mobilePicture,
    locationInfo,
    descriptionTranslation,
    zeroPriced,
    price,
    length,
    lengthType,
    schedule,
    bookNowButton,
    online,
    hasMultipleLocations
  } = experience.attributes;

  const retainValue = value => value;
  moment.relativeTimeRounding(retainValue);

  const productAttribute = (experience, isGa4Enabled) => {
    var data = null;
    const id = experience.attributes.id;
    const name = experience.attributes.nameTranslation;

    if (isGa4Enabled) {
      data = {
        item_id: id,
        item_name: name
      };
    } else {
      data = {
        id: id,
        name: name
      };
    }

    return data;
  };

  const navigate = e => {
    const position = isGa4Enabled ? index : undefined;
    const price = isGa4Enabled ? experience.attributes.unformattedPrice : price;

    if (trackProductClick) {
      fireProductClick(
        {
          ...(isGa4Enabled ? { index } : { position }),
          price,
          affiliation: 'AnyRoad',
          ...productAttribute(experience, isGa4Enabled)
        },
        isGa4Enabled,
        pluginName,
        pluginId
      );
    }
    if (bookNowNewTab) {
      e.preventDefault();
      window.open(url);
    }
  };

  return (
    <CardWrapper className={experienceCard} data-testid="experience-card">
      <Card onClick={navigate} href={url} dimmed={false}>
        <CardGrid gridTemplateColumns="265px 1fr">
          <Picture
            picture={picture}
            mobilePicture={mobilePicture}
            nameTranslation={nameTranslation}
            displayPrice={isMobile}
            zeroPriced={zeroPriced}
            price={price}
          />
          <Info className={experienceTitleAndDescription} gridTemplateRows="1fr 50px">
            <InfoMain>
              <Header
                nameTranslation={nameTranslation}
                taglineTranslation={taglineTranslation}
                locationInfo={locationInfo}
                useLocationAsSubtitle={useLocationAsSubtitle}
                isOnline={online}
                hasMultipleLocations={hasMultipleLocations}
              />
              <HR />
              <Schedule schedule={schedule} />
              <Description
                className={experienceDescription}
                dangerouslySetInnerHTML={{
                  __html: descriptionTranslation
                }}
              />
            </InfoMain>
            <InfoFooter className={bookTourDetails} gridTemplateColumns="140px 1fr 1fr">
              <div>
                <Time length={length} lengthType={lengthType} />
              </div>
              <div>{!isMobile ? <Price zeroPriced={zeroPriced} price={price} /> : <span />}</div>
              <ExperienceButton
                className={bookExperienceButton}
                data-testid="book-now-btn"
                aria-label={`${bookNowButton}: ${nameTranslation}`}
              >
                {bookNowButton}
              </ExperienceButton>
            </InfoFooter>
          </Info>
        </CardGrid>
      </Card>
    </CardWrapper>
  );
};

const ExperienceCardLoading = () => (
  <CardWrapper className={experienceCard}>
    <Card>
      <CardGrid gridTemplateColumns="250px 1fr" loading>
        <PictureLoader>
          <Loader />
        </PictureLoader>
        <Info className={experienceTitleAndDescription} gridTemplateRows="1fr 50px" loading>
          <InfoMain loading>
            <Loader style={{ display: 'block', marginBottom: '5px' }} height={18} width={230} />
            <Loader height={16} width={230} />
            <HR />
            <Loader height={36} width="80%" />
          </InfoMain>
          <LoadingFooter gridTemplateColumns="200px 1fr 90px">
            <Loader width={80} height={18} />
            <Loader width={60} height={18} />
            <Loader width={90} height={38} />
          </LoadingFooter>
        </Info>
      </CardGrid>
    </Card>
  </CardWrapper>
);

export default ExperienceCard;
