import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from '@emotion/styled/macro';

import CalendarIcon from '../../../../shared/icons/calendar-icon';
import { experienceDatesAvailable } from '../../../../shared/utils/staticCssClasses';
import { Theme } from '../../../../shared/styles';

import { midCssBreak } from '../../../common_styles';

const InfoSchedule = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 3px;
  }

  svg {
    margin-right: 10px;
    margin-bottom: 2px;
    fill: ${Theme.colors.icon};
  }
  @media (max-width: ${midCssBreak}) {
    font-size: 13px;
  }
`;

const translateDate = date => {
  const { t } = useTranslation();
  const parsedDate = moment.parseZone(date);
  const day = parsedDate.format('D');
  const month = parsedDate.format('MMM').slice(0, 3).toLowerCase();

  return t('dateAbbreviated', { month: t(month), day: day });
};

export const Schedule = ({ schedule }) => {
  const { t } = useTranslation();

  const maxDates = 3;

  if (schedule) {
    const scheduleDates = Object.keys(schedule);
    return (
      <InfoSchedule className={experienceDatesAvailable}>
        <CalendarIcon />
        {scheduleDates.slice(0, maxDates).map((date, i, array) => (
          <span key={date}>
            {translateDate(date)}
            {i < array.length - 1 && ','}
          </span>
        ))}
        {scheduleDates.length > maxDates && <span> & {t('more')}... </span>}
      </InfoSchedule>
    );
  }
  return null;
};
