import React from 'react';

const ClockIcon = props => (
  <svg id="prefix__Layer_1" x={0} y={0} width={16.2} height={16.2} xmlSpace="preserve" {...props}>
    <title>Duration</title>
    <path
      id="prefix__XMLID_5_"
      className="prefix__st0"
      d="M10.9 11.4c-.1 0-.3 0-.4-.1l-3-3V2.8c0-.3.2-.5.5-.5s.5.2.5.5v5.1l2.7 2.7c.2.2.2.5 0 .7 0 0-.2.1-.3.1z"
    />
    <path
      id="prefix__XMLID_2_"
      className="prefix__st0"
      d="M8.1 16.2C3.6 16.2 0 12.5 0 8.1 0 3.6 3.6 0 8.1 0s8.1 3.6 8.1 8.1c0 4.4-3.7 8.1-8.1 8.1zM8.1 1C4.2 1 1 4.2 1 8.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1S12 1 8.1 1z"
    />
  </svg>
);

export default ClockIcon;
