export const experiencesListWrapper = 'website-tours-wrapper';
export const experiencesList = 'website-tours';
export const noResultsMessage = 'no-results-message';
/* EXPERIENCE CARD CLASSNAMES */
export const experienceCard = 'tour-wrapper';
export const experienceDatesAvailable = 'tour-dates-available';
export const experienceTitleAndDescription = 'tour-title-and-description';
export const experienceDescription = 'tour-description';
export const experienceDuration = 'tour-duration';
export const experienceDurationIcon = 'clock-icon';
export const experiencePrice = 'tour-price';
export const experiencePriceType = 'price-type';
export const experiencePriceInfo = 'price-info';
export const experienceSubtitle = 'tour-tagline';
export const experienceTitle = 'tour-title';
export const bookExperienceButton = 'book-tour-btn button brand-bg';
export const bookTourDetails = 'book-tour-details';
/* FILTER CLASSNAMES */
export const filterBar = 'filter-bar';
export const filterButtons = 'filter-buttons';
export const filterButton = 'filter-button';
/* PAGINATION CLASSNAMES */
export const paginator = 'paginator';
export const paginateContainer = 'paginate-container';
export const pageNumbers = 'page-numbers';
export const paginateButton = 'paginate-button';
/* SEARCH BAR CLASSNAMES */
export const searchBar = 'search-bar';
export const searchBarInput = 'search-bar-input';

/* LANGUAGE SELECTOR CLASSNAMES */
export const languageHeader = 'language-header';
export const languageSelector = 'language-selector';
