import React from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { getPluginTheme } from '../../../redux/plugins/selectors';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: ${props => (props.theme.headerColor ? props.theme.headerColor : 'white')};
`;

const Img = styled.img`
  max-height: 60px;
  max-width: 100%;
`;

export const Header = () => {
  const { headerLogo } = useSelector(getPluginTheme);
  const isOutsideIframe = window === window.top;

  return isOutsideIframe && headerLogo ? (
    <Container>
      <Img src={headerLogo} />
    </Container>
  ) : null;
};
