import styled from '@emotion/styled/macro';

import { smallCssBreak, midCssBreak } from '../../../common_styles';

import { ReactImage } from './ReactImage';
import { Price } from './Price';

const Image = styled(ReactImage)`
  margin-right: 15px;
  width: 250px;
  height: 250px;
  display: flex;
  align-self: center;
  @media (max-width: ${midCssBreak}) {
    width: 210px;
    height: 210px;
  }
  @media (max-width: ${smallCssBreak}) {
    display: none;
  }
`;

const MobileImage = styled(Image)`
  display: none;
  @media (max-width: ${smallCssBreak}) {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const GradientWrapper = styled.div`
  @media (max-width: ${smallCssBreak}) {
    position: relative;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 0;
      background: -moz-linear-gradient(top, transparent 65%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(top, transparent 65%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(top, transparent 65%, rgba(0, 0, 0, 0.8) 100%);
    }
  }
`;

export const Picture = ({
  picture,
  mobilePicture,
  nameTranslation,
  displayPrice,
  zeroPriced,
  price
}) => (
  <GradientWrapper>
    <Image srcList={[picture, '/default_experience_image.png']} alt="" />
    <MobileImage srcList={[mobilePicture, picture, '/default_experience_image.png']} alt="" />
    {displayPrice ? <Price zeroPriced={zeroPriced} price={price} /> : null}
  </GradientWrapper>
);
