import React, { Component, useState } from 'react';

import styled from '@emotion/styled/macro';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { withTranslation, useTranslation } from 'react-i18next';

import LocationIcn from '../../icons/location-icon';
import CloseIcn from '../../icons/close-icon';

import { Theme, textInputCss } from '../../styles';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  ${textInputCss};
  position: relative;
  padding-right: 40px;
  padding-left: 40px;
  text-overflow: ellipsis;

  svg {
    fill: ${Theme.colors.icon};
  }

  @media (max-width: 767px) {
    height: unset;
  }
`;

const LocationIcon = styled(LocationIcn)`
  margin: auto;
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 15px;
  fill: ${Theme.colors.icon};
`;

const CloseIcon = styled(CloseIcn)`
  fill: ${Theme.colors.icon};
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 100;
  top: 47px;
  left: 0px;
  height: auto;
  width: 100%;
  border: 1px solid #ccc;
  border-top: 1px solid #ccc;

  background-color: white;

  /* Prevent screen overlap */
  margin-bottom: 50px;
  overflow: auto;

  opacity: 1;
  transition: all 200ms ease-in;
  display: block;
  -webkit-animation: fadeInFromNone 0.3s ease-out;
  -moz-animation: fadeInFromNone 0.3s ease-out;
  -o-animation: fadeInFromNone 0.3s ease-out;
  animation: fadeInFromNone 0.3s ease-out;

  display: ${props => (props.open ? 'block' : 'none')};

  /* Mobile tweaks */
  @media (max-width: 700px) {
    width: 90vw;
  }
`;

const DropdownItem = styled.div`
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.lightBackground};
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  cursor: pointer;
`;

class PlacesInput extends Component {
  handleChange = address => {
    const { setAddress } = this.props;
    setAddress(address);
  };

  handleSelect = address => {
    const { setAddress, onAddressSelect } = this.props;
    setAddress(address);
    onAddressSelect(address);
  };

  // Only allow exact city/zip selections based on 'cityAndZipOnly' prop
  filterSuggestions = suggestions => {
    const { cityAndZipOnly } = this.props;
    if (!cityAndZipOnly) return suggestions;

    return suggestions.filter(suggestion => {
      const { types } = suggestion;
      return types.includes('locality') || types.includes('postal_code');
    });
  };

  onBlur = defaultOnBlur => {
    const { setAddress, address, selectedLocation } = this.props;

    if (address !== selectedLocation) {
      setAddress(selectedLocation);
    }

    defaultOnBlur();
  };

  render() {
    const { t, searchOptions, address } = this.props;

    return (
      <PlacesAutocomplete
        value={address || ''}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Container>
            <Input
              {...getInputProps({
                placeholder: t('enterALocation')
              })}
              ref={input => {
                this.input = input;
              }}
              onBlur={() => this.onBlur(getInputProps().onBlur)}
              data-testid="location-input"
            />
            <LocationIcon role="button" aria-label="add location" height={40} />
            {address && address.length > 0 ? (
              <CloseIconWrapper
                onClick={() => {
                  this.handleChange('');
                  this.input.focus();
                }}
                data-testid="clear-location-input"
                role="button"
                aria-label="clear location"
              >
                <CloseIcon />
              </CloseIconWrapper>
            ) : null}
            <Dropdown open={this.filterSuggestions(suggestions).length > 0}>
              {this.filterSuggestions(suggestions).map(suggestion => (
                <DropdownItem
                  {...getSuggestionItemProps(suggestion, {})}
                  key={suggestion.description}
                  data-testid="location-suggestion"
                >
                  <span>{suggestion.description}</span>
                </DropdownItem>
              ))}
            </Dropdown>
          </Container>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default withTranslation()(PlacesInput);
