import { useImage } from 'react-image';

export const ReactImage = ({ srcList, alt, className }) => {
  const { src } = useImage({ srcList, useSuspense: false });

  return (
    <>
      <img src={src} alt={alt} className={className} />
    </>
  );
};
