import i18n from 'i18next';
import moment from 'moment';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import Api from './api';

import { fetchPlugin, fetchPluginSuccess, fetchPluginFailure, changeI18n } from './actions';
import { getQueryParams } from '../router/selectors';

function* fetchPluginSaga({ payload: { id } }) {
  try {
    const response = yield call(Api.fetchPlugin, { id });
    const queryParams = yield select(getQueryParams);

    const plugin = response.data;
    const lang = queryParams.lang || plugin.languageSettings.defaultLanguage;

    yield put(changeI18n({ i18n: lang }));
    moment.locale(lang);
    i18n.changeLanguage(lang);

    yield put(fetchPluginSuccess({ plugin }));
  } catch (error) {
    yield put(fetchPluginFailure({ error }));
  }
}

export const sagas = {
  listenForFetchPluginSaga: takeLatest(fetchPlugin.type, fetchPluginSaga)
};
