import { createSelector } from '@reduxjs/toolkit';

export const getPlugin = state => state.plugins?.plugin;
export const getPluginId = createSelector(getPlugin, ({ id }) => id);
export const getPluginType = createSelector(getPlugin, ({ type }) => type);
export const getPluginName = createSelector(getPlugin, ({ name }) => name);
export const getPluginHeadScript = createSelector(getPlugin, ({ headScript }) => headScript);
export const getIsGa4Enabled = createSelector(getPlugin, ({ isGa4Enabled }) => isGa4Enabled);

export const getPluginStylesheet = createSelector(
  getPlugin,
  ({ externalStylesheet }) => externalStylesheet
);
export const getPluginTheme = createSelector(getPlugin, ({ theme }) => {
  if (!theme) return {};

  /* eslint-disable no-param-reassign */
  const themeWithoutNullValues = {};
  Object.keys(theme).forEach(key => {
    if (theme[key] && theme[key].length > 0) themeWithoutNullValues[key] = theme[key];
  });

  return themeWithoutNullValues;
});
/* eslint-enable no-param-reassign */

const getPluginFilterSettings = createSelector(getPlugin, ({ filters }) => filters);
export const isDateFilterEnabled = createSelector(
  getPluginFilterSettings,
  filters => filters.dateFilter
);
export const isGuestFilterEnabled = createSelector(
  getPluginFilterSettings,
  filters => filters.guestFilter
);
export const isSearchFilterEnabled = createSelector(
  getPluginFilterSettings,
  filters => filters.searchFilter
);
export const isLocationFilterEnabled = createSelector(
  getPluginFilterSettings,
  filters => filters.locationFilter
);
export const getAllCategories = createSelector(
  getPluginFilterSettings,
  filters => filters.categories || []
);

export const getOpenInNewTab = createSelector(getPlugin, ({ openInNewTab }) => openInNewTab);

export const getCustomNoLocationText = createSelector(
  getPlugin,
  ({ customNoLocationText }) => customNoLocationText
);
