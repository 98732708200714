export const fireProductClick = (experienceDetails, isGa4Enabled, pluginName, pluginId) => {
  postGTMData({ ecommerce: null }); // Clear the previous ecommerce object.
  const items = isGa4Enabled ? [experienceDetails] : undefined;
  var data = null;

  if (isGa4Enabled) {
    data = {
      event: 'select_item',
      ecommerce: {
        item_list_id: pluginId,
        item_list_name: pluginName,
        items
      }
    };
  } else {
    data = {
      event: 'product_click',
      url: window.document.location.href,
      ecommerce: {
        click: {
          products: experienceDetails
        }
      }
    };
  }

  postGTMData(data);
};

const formatExperiences = (experiences, isGa4Enabled) => {
  var experienceList = null;

  if (isGa4Enabled) {
    experienceList = experiences.map((el, idx) => ({
      item_id: el.attributes.id,
      item_name: el.attributes.nameTranslation,
      index: idx + 1,
      price: el.attributes.unformattedPrice,
      affiliation: 'AnyRoad'
    }));
  } else {
    experienceList = experiences.map((el, idx) => ({
      id: el.attributes.id,
      name: el.attributes.nameTranslation,
      price: el.attributes.price,
      position: idx + 1
    }));
  }
  return experienceList;
};

export const fireImpressions = (experienceList, isGa4Enabled, pluginName, pluginId) => {
  postGTMData({ ecommerce: null }); // Clear the previous ecommerce object.
  const formattedExperiences = formatExperiences(experienceList, isGa4Enabled);
  const items = isGa4Enabled ? formattedExperiences : undefined;
  var data = null;

  if (isGa4Enabled) {
    data = {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: pluginId,
        item_list_name: pluginName,
        items
      }
    };
  } else {
    data = {
      event: 'impressions',
      url: window.document.location.href,
      ecommerce: {
        impressions: formattedExperiences
      }
    };
  }
  postGTMData(data);
};

const postGTMData = data => {
  if ('parentIFrame' in window && typeof window.parent.postMessage !== 'undefined') {
    window.parent.postMessage(data, '*');
  } else if (window.dataLayer) {
    window.dataLayer.push(data);
  }
};
