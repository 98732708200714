import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { updateFilters } from '../../../redux/experiences/actions';
import { getFilters } from '../../../redux/experiences/selectors';

import { setSelectedLocation, setCurrentFilter } from '../../../redux/filters/actions';
import { getCurrentFilters } from '../../../redux/filters/selectors';

import {
  getPlugin,
  isDateFilterEnabled,
  isGuestFilterEnabled,
  isLocationFilterEnabled,
  getAllCategories
} from '../../../redux/plugins/selectors';

import DateFilter from '../filters/DateFilter';
import GuestFilter from '../filters/GuestFilter';
import LocationFilter from '../filters/LocationFilter';
import { CategoryFilter } from '../filters/CategoryFilter';

export const FiltersDesktop = ({ toggleFadeList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { useKmForDistance } = useSelector(getPlugin);
  const currentFilters = useSelector(getCurrentFilters);

  const dateFilterEnabled = useSelector(isDateFilterEnabled);
  const guestFilterEnabled = useSelector(isGuestFilterEnabled);
  const locationFilterEnabled = useSelector(isLocationFilterEnabled);
  const allCategories = useSelector(getAllCategories);

  const filters = useSelector(getFilters);
  const { guestCount, startDate, endDate, locationQuery, categories: appliedCategories } = filters;
  const { currentLocationQuery, currentLocationRadius, selectedLocationQuery } = currentFilters;

  const [cacheKey, setCacheKey] = useState(0);
  const updateCacheKey = () => setCacheKey(cacheKey + 1);

  const tippyInstances = {
    guest: useRef(),
    date: useRef(),
    location: useRef()
  };

  const tippyProps = tippyName => ({
    onCreate: instance => {
      tippyInstances[tippyName].current = instance;
    },
    onShow: () => toggleFadeList(true),
    onHide: () => toggleFadeList(false),
    onHidden: () => updateCacheKey()
  });

  let dateLabel = '';
  const start = moment(startDate);
  const end = moment(endDate);
  if (start.isSame(end, 'day')) {
    dateLabel = `${start.format('MMM D, YYYY')}`;
  } else if (start.isSame(end, 'month')) {
    dateLabel = `${start.format('MMM D')} – ${end.format('D')}, ${start.format('YYYY')}`;
  } else if (start.isSame(end, 'year')) {
    dateLabel = `${start.format('MMM D')} – ${end.format('MMM D')}, ${start.format('YYYY')}`;
  } else {
    dateLabel = `${start.format('MMM D, YYYY')} – ${end.format('MMM D, YYYY')}`;
  }
  const guestText = guestCount > 1 ? t('guests') : t('guest');

  return (
    <>
      {locationFilterEnabled && (
        <LocationFilter
          address={currentLocationQuery}
          radius={currentLocationRadius}
          setAddress={location => dispatch(setCurrentFilter({ location }))}
          setRadius={radius => dispatch(setCurrentFilter({ radius }))}
          applied={locationQuery}
          popoverProps={tippyProps('location')}
          buttonLabel={locationQuery}
          selectedLocation={selectedLocationQuery}
          setSelectedLocation={location => dispatch(setSelectedLocation({ location }))}
          useKmForDistance={useKmForDistance}
          applyFilter={() => {
            dispatch(
              updateFilters({
                locationQuery: currentLocationQuery,
                locationRadius: currentLocationRadius
              })
            );
            tippyInstances.location.current.hide();
          }}
        />
      )}
      {dateFilterEnabled && (
        <DateFilter
          applied={startDate && endDate}
          popoverProps={tippyProps('date')}
          buttonLabel={dateLabel}
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          cacheKey={cacheKey}
          applyFilter={({ startDate: newStartDate, endDate: newEndDate }) => {
            dispatch(
              updateFilters({
                startDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : null,
                endDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : null
              })
            );
            tippyInstances.date.current.hide();
          }}
          clearFilter={() => {
            dispatch(updateFilters({ startDate: null, endDate: null }));
            tippyInstances.date.current.hide();
          }}
        />
      )}
      {guestFilterEnabled && (
        <GuestFilter
          applied={guestCount?.length > 0}
          popoverProps={tippyProps('guest')}
          buttonLabel={`${guestCount} ${guestText}`}
          guestCount={guestCount}
          cacheKey={cacheKey}
          applyFilter={newGuestCount => {
            dispatch(updateFilters({ guestCount: newGuestCount }));
            tippyInstances.guest.current.hide();
          }}
          clearFilter={() => {
            dispatch(updateFilters({ guestCount: null }));
            tippyInstances.guest.current.hide();
          }}
        />
      )}
      {allCategories.length > 0 && (
        <CategoryFilter
          applied={!!appliedCategories}
          buttonLabel={`${t('categories')} (${appliedCategories?.length})`}
          allCategories={allCategories}
          applyFilter={newCategories => {
            dispatch(updateFilters({ categories: newCategories }));
          }}
          clearFilter={() => {
            dispatch(updateFilters({ categories: null }));
          }}
        />
      )}
    </>
  );
};
