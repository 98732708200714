import { useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled/macro';
import { ReactComponent as Checkmark } from '../../../shared/svg/checkmark.svg';
import { Theme } from '../../../shared/styles';

const Container = styled.div`
  width: fit-content;

  @media (max-width: 767px) {
    width: 100%;

    &:not(:first-of-type) {
      border-top: 1px solid ${Theme.colors.lightBorder};
      padding-top: 10px;
    }

    &:not(:last-of-type) {
      padding-bottom: 10px;
    }
  }
`;

const InvisibleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;

  /* focus styles only apply when tabbing through inputs */
  &:focus-visible + label > div:first-of-type {
    outline: auto Highlight;
    outline: auto -webkit-focus-ring-color;
  }
`;

const Label = styled.label`
  font-size: ${props => props.textSize};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Box = styled.div`
  border: 1px solid ${props => (props.checked ? props.boxColor : '#d3d3d3')};
  background-color: ${props => (props.checked ? props.boxColor : '#f5f5f5')};
  width: ${props => props.boxSize};
  height: ${props => props.boxSize};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  flex: none;

  @media (max-width: 767px) {
    margin-right: 0px;
    margin-left: 14px;
  }
`;

const StyledCheck = styled(Checkmark, {
  // Prevent prop forwarding to SVG element so they don't end up in the DOM
  shouldForwardProp: () => false
})(props => ({
  opacity: props.checked ? 1 : 0,
  fill: 'white',
  width: `calc(${props.boxSize}/2)`,
  height: `calc(${props.boxSize}/2)`
}));

// idx guarantees a unique id between checkboxes that are in the same list
const randomId = idx =>
  `${idx}-` + [...Array(8)].map(_ => ((Math.random() * 36) | 0).toString(36)).join('');

export const Checkbox = ({
  idx = 0,
  children = <span />,
  isChecked = false,
  onChange = () => {},
  boxSize = '26px',
  boxColor = '#8ec63f',
  textSize = '16px'
}) => {
  const id = useMemo(() => randomId(idx), []);
  const dataTestId = idx;

  return (
    <Container>
      <InvisibleInput
        id={id}
        data-testid={dataTestId}
        type="checkbox"
        aria-checked={isChecked}
        onChange={() => onChange(!isChecked)}
      />
      <Label htmlFor={id} textSize={textSize}>
        <Box checked={isChecked} boxSize={boxSize} boxColor={boxColor}>
          <StyledCheck checked={isChecked} boxSize={boxSize} />
        </Box>
        {children}
      </Label>
    </Container>
  );
};

Checkbox.propTypes = {
  idx: PropTypes.number,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  boxSize: PropTypes.string,
  boxColor: PropTypes.string,
  textSize: PropTypes.string
};
