import React from 'react';

const CalendarIcon = props => (
  <svg id="prefix__Layer_1" x={0} y={0} width={19} height={19} xmlSpace="preserve" {...props}>
    <title>Experience Dates</title>
    <g id="prefix__calendar_6_">
      <path
        id="prefix__XMLID_214_"
        className="prefix__st0"
        d="M17.4 1.8h-2.6V.6c0-.3-.3-.6-.6-.6s-.6.3-.6.6v1.2H10V.6c.1-.3-.2-.6-.5-.6s-.6.3-.6.6v1.2H5.3V.6c0-.3-.2-.6-.6-.6-.3 0-.5.3-.5.6v1.2H1.6C.7 1.8 0 2.5 0 3.4v14.1c0 .8.7 1.5 1.6 1.5h15.8c.9 0 1.6-.7 1.6-1.6v-14c0-.9-.7-1.6-1.6-1.6zm.4 15.6c0 .2-.2.4-.4.4H1.6c-.2 0-.4-.2-.4-.4v-14c0-.3.2-.4.4-.4h2.6v1.2c0 .3.3.6.6.6s.6-.3.6-.6V3H9v1.2c0 .3.3.6.6.6s.6-.3.6-.6V3h3.6v1.2c0 .3.3.6.6.6s.6-.3.6-.6V3h2.6c.2 0 .4.2.4.4v14z"
      />
      <path id="prefix__XMLID_213_" className="prefix__st0" d="M4.2 7.1h2.4v1.8H4.2z" />
      <path id="prefix__XMLID_211_" className="prefix__st0" d="M4.2 10.1h2.4v1.8H4.2z" />
      <path id="prefix__XMLID_195_" className="prefix__st0" d="M4.2 13.1h2.4v1.8H4.2z" />
      <path id="prefix__XMLID_194_" className="prefix__st0" d="M8.3 13.1h2.4v1.8H8.3z" />
      <path id="prefix__XMLID_193_" className="prefix__st0" d="M8.3 10.1h2.4v1.8H8.3z" />
      <path id="prefix__XMLID_192_" className="prefix__st0" d="M8.3 7.1h2.4v1.8H8.3z" />
      <path id="prefix__XMLID_191_" className="prefix__st0" d="M12.5 13.1h2.4v1.8h-2.4z" />
      <path id="prefix__XMLID_178_" className="prefix__st0" d="M12.5 10.1h2.4v1.8h-2.4z" />
      <path id="prefix__XMLID_176_" className="prefix__st0" d="M12.5 7.1h2.4v1.8h-2.4z" />
    </g>
  </svg>
);

export default CalendarIcon;
