import { createSelector } from '@reduxjs/toolkit';
import { getQueryParams } from '../router/selectors';

export const getExperiences = ({ experiences }) => experiences.experiences;
export const getExperiencesFetching = ({ experiences }) => experiences.experiencesFetching;
export const getExperiencesFetchingError = ({ experiences }) =>
  experiences.experiencesFetchingError;

export const getTotalPages = ({ experiences }) => experiences.totalPages;

export const getPage = createSelector(getQueryParams, ({ page }) => page || 1);
export const getI18n = createSelector(getQueryParams, ({ lang }) => lang);
export const getRandomizationSeed = createSelector(
  getQueryParams,
  ({ randomizationSeed }) => randomizationSeed
);

// Filter states
export const getFilters = createSelector(
  getQueryParams,
  ({ startDate, endDate, guestCount, searchQuery, locationQuery, locationRadius, categories }) => ({
    startDate, // Date Filter - start date
    endDate, // Date Filter - end date
    guestCount, // Guest Filter
    searchQuery, // Search Box
    locationQuery,
    locationRadius,
    categories: categories?.split(',')
  })
);

// Returns # of filters applied which live inside the modal on mobile screens
export const getMobileFiltersAppliedCount = createSelector(
  getFilters,
  ({ startDate, endDate, guestCount, categories }) => {
    let count = 0;
    if (startDate || endDate) count += 1;
    if (guestCount) count += 1;
    if (categories?.length > 0) count += 1;

    return count;
  }
);
