/*
  FilterBar Component
  Overarching wrapper for all of the child filters
  Stores internal state for temporary filter state
  Manages interaction with Redux state when applying filters
*/

import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { updateFilters } from '../../../redux/experiences/actions';
import { getFilters } from '../../../redux/experiences/selectors';
import {
  isDateFilterEnabled,
  isGuestFilterEnabled,
  isSearchFilterEnabled
} from '../../../redux/plugins/selectors';

import { Theme } from '../../../shared/styles';
import { filterBar, filterButtons } from '../../../shared/utils/staticCssClasses';
import SearchFilter from './../filters/SearchFilter';

import { FiltersMobile } from './FiltersMobile';
import { FiltersDesktop } from './FiltersDesktop';

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 25px;
  border-top: ${({ hideBorders }) =>
    hideBorders ? 'none' : `1px solid ${Theme.colors.lightLine}`};
  border-bottom: ${({ hideBorders }) =>
    hideBorders ? 'none' : `1px solid ${Theme.colors.lightLine}`};

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const FilterBar = ({ toggleFadeList }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const filters = useSelector(getFilters);
  const dateFilterEnabled = useSelector(isDateFilterEnabled);
  const guestFilterEnabled = useSelector(isGuestFilterEnabled);
  const searchFilterEnabled = useSelector(isSearchFilterEnabled);

  const hideBorders = !(dateFilterEnabled && guestFilterEnabled && searchFilterEnabled);

  const { searchQuery } = filters;

  return (
    <Container className={filterBar} data-testid="filters-bar" hideBorders={hideBorders}>
      <ButtonContainer className={filterButtons}>
        {isMobile ? (
          <FiltersMobile toggleFadeList={toggleFadeList} />
        ) : (
          <FiltersDesktop toggleFadeList={toggleFadeList} />
        )}
      </ButtonContainer>
      {searchFilterEnabled && (
        <SearchFilter
          searchQuery={searchQuery}
          applyFilter={newQuery => dispatch(updateFilters({ searchQuery: newQuery }))}
          clearFilter={() => dispatch(updateFilters({ searchQuery: null }))}
        />
      )}
    </Container>
  );
};
