/*
  PluginService
  Responsible for:
    - Loading Partner config into state
    - Loading in Partner-specific custom stylesheets
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPlugin } from '../redux/plugins/actions';
import {
  getPluginName,
  getPluginStylesheet,
  getPluginHeadScript
} from '../redux/plugins/selectors';

export const PluginService = ({ match }) => {
  const dispatch = useDispatch();
  const pluginName = useSelector(getPluginName);
  const pluginStylesheet = useSelector(getPluginStylesheet);
  const pluginHeadScript = useSelector(getPluginHeadScript);

  // Load correct plugin on URL change
  useEffect(() => {
    dispatch(fetchPlugin({ id: match.params.pluginId }));
  }, [match.params.pluginId, dispatch]);

  // Update document title if plugin changes
  useEffect(() => {
    if (pluginName && pluginName.length > 0) document.title = `${pluginName}`;
  }, [pluginName]);

  // Load scripts they have defined on the user level
  useEffect(() => {
    if (pluginHeadScript) {
      const parser = new window.DOMParser();
      const html = parser.parseFromString(pluginHeadScript, 'text/html');

      const scripts = html.querySelectorAll('script') || [];
      scripts.forEach(script => {
        window.document.head.appendChild(script);
        // eslint-disable-next-line no-eval
        eval(script.text);
      });
    }
  }, [pluginHeadScript]);

  return (
    <>
      {pluginStylesheet ? <link rel="stylesheet" type="text/css" href={pluginStylesheet} /> : null}
    </>
  );
};
