import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import { PluginService } from './plugin/PluginService';
import { PluginRenderer } from './plugin/PluginRenderer';
import { PluginThemeService } from './plugin/PluginThemeService';
import { I18nService } from './i18n/I18nService';
import PluginPreviewHeader from './plugin/PluginPreviewHeader';

import { store, history } from './redux';
import { Header } from './experience_list/components/header/Header';

require('iframe-resizer/js/iframeResizer.contentWindow.min.js');

class App extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboardUser);

    if ('parent' in window) {
      window.parent.postMessage('initialize-resizer', '*');
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyboardUser);
  };

  handleKeyboardUser = e => {
    if (e.keyCode === 9) {
      document.body.classList.add('keyboardUser');
      window.removeEventListener('keydown', this.handleKeyboardUser);
    }
  };

  render() {
    return (
      <Provider store={store}>
        <PluginThemeService>
          <ConnectedRouter history={history}>
            <div>
              <PluginPreviewHeader />
              <Header />
              <Route path="/:pluginId" component={PluginService} />
              <Route path="/:pluginId" component={I18nService} />
              <Route path="/:pluginId" component={PluginRenderer} />
            </div>
          </ConnectedRouter>
        </PluginThemeService>
      </Provider>
    );
  }
}

export default App;
