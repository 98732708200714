const enUS = {
  translation: {
    apply: 'Apply',
    apr: 'Apr',
    aug: 'Aug',
    bookNow: 'Book Now',
    cancel: 'Cancel',
    categories: 'Categories',
    category: 'Category',
    chinese_simplified: 'Chinese Simplified',
    chinese_traditional: 'Chinese Traditional',
    clear: 'Clear',
    dateAbbreviated: '{{month}} {{day}}',
    dates: 'Dates',
    datesAvailable: 'Dates Available',
    day: 'day',
    days: 'days',
    dec: 'Dec',
    dutch: 'Dutch',
    enterALocation: 'Enter a Location',
    feb: 'Feb',
    filters: 'Filters',
    french: 'French',
    fromPrice: 'From',
    fromTime: 'From',
    german: 'German',
    greek: 'Greek',
    guest: 'Guest',
    guests: 'Guests',
    hour: 'hour',
    hours: 'hours',
    Italian: 'Italian',
    jan: 'Jan',
    jul: 'Jul',
    jun: 'Jun',
    kilometers: 'Kilometers',
    location: 'Location',
    mar: 'Mar',
    may: 'May',
    miles: 'miles',
    minutes: 'minutes',
    more: 'more',
    next: 'Next',
    noFilterResults: 'Sorry, we found no results for the selected filters.',
    noResults: 'Sorry, we could not find any experiences.',
    noResultsDates: 'Sorry, we found no results for the selected dates.',
    noResultsFuture: 'Sorry, we have no available experiences at this time.',
    noResultsGuests: 'Sorry, we found no results for the selected number of guests.',
    noResultsWithin:
      'Sorry, we found no results within <strong>{{distance}} {{units}}</strong> of <strong>{{location}}.</strong>',
    noSearchResults: 'Sorry, we found no results for "<strong>{{searchQuery}}</strong>".',
    nov: 'Nov',
    numberOfGuests: 'Number of Guests',
    oct: 'Oct',
    online: 'Online',
    previous: 'Previous',
    search: 'Search',
    select: 'Select',
    sep: 'Sep',
    swedish: 'Swedish',
    to: 'To',
    widenSearch: 'Try widening your search by changing or clearing your filters.',
    within: 'Within'
  }
};

export default enUS;
