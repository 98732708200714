import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import Theme from './Theme';

export { Theme };

export const Grid = styled.div`
  display: grid;
  gap: ${props => props.gap};

  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-template-rows: ${props => props.gridTemplateRows};

  ${props =>
    props.center &&
    `
    justify-content: center;
    align-items: center;
    `};

  @media (max-width: ${Theme.mobile.mobileBreakpointWidth}px) {
    ${props =>
      props.responsiveTemplateColumns &&
      `grid-template-columns: ${props.responsiveTemplateColumns}`};
    ${props =>
      props.responsiveTemplateRows && `grid-template-rows: ${props.responsiveTemplateRows}`};
  }
`;

export const cssWordBreak = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const cssRemoveInputFocus = css`
  :focus {
    outline: none;
  }
`;

export const cssHoverable = css`
  &:hover {
    filter: brightness(110%) saturate(125%);
  }
  &:disabled {
    filter: none;
    opacity: 0.3;
  }
`;

export const sharedButtonProperties = css`
  background-color: ${props => (props.bgColor ? props.bgColor : '#fff')};
  border-radius: 4px;
  border: solid 1px ${props => (props.color ? props.color : Theme.colors.inputs.borderAction)};
  color: ${props => (props.color ? props.color : Theme.colors.mediumText)};
  font-size: 14px;
  padding: 10px 0;
  width: 120px;
  cursor: pointer;

  i {
    margin-right: 10px;
  }

  @media (max-width: ${Theme.mobile.mobileBreakpointWidth}px) {
    font-size: 13px;
    padding: 8px 24px;
  }

  opacity: ${props => (props.disabled ? '0.4' : '1')};

  ${cssRemoveInputFocus};
  ${props => (props.hoverable && !props.disabled ? cssHoverable : '')};
`;

export const Button = styled.button`
  ${sharedButtonProperties}
`;

export const Span = styled.span`
  ${sharedButtonProperties};
`;

export const BrandButton = styled(Button)`
  background-color: ${props => (props.inverted ? 'white' : props.theme.brandColor)};
  color: ${props => (props.inverted ? props.theme.brandColor : 'white')};
  border: ${props =>
    props.noBorder ? '1px solid transparent' : `1px solid ${props.theme.brandColor}`};
  text-align: ${props => (props.inverted ? 'left' : 'default')};
`;

export const CircleAvatar = styled.div`
  ${({ size }) => css`
    background-color: ${Theme.colors.darkText};
    border-radius: 50%;
    color: #fff;
    font-size: ${size ? `${parseInt(size, 10) / 3}px` : '14px'};
    font-weight: lighter;
    height: ${size ? `${size}px` : '32px'} !important;
    line-height: ${size ? `${size}px` : '32px'};
    overflow: hidden;
    position: relative;
    text-align: center;
    top: ${size ? `-${parseInt(size, 10) / 4}px` : '32px'};
    width: ${size ? `${size}px` : '32px'} !important;
    img {
      width: ${size ? `${size}px` : '32px'};
      height: ${size ? `${size}px` : '32px'};
      vertical-align: top;
    }
  `}
`;

export const HR = styled.hr`
  border: none;
  background: #e6e6e6;
  height: 1px;
  outline: none;
  margin: 10px 0;
`;

export const TruncatedGradient = styled.div`
  content: '';
  position: absolute;
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '50px')};
  left: 0;
  bottom: ${props => (props.bottom ? `${props.bottom}px` : '0')};
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
`;

export const textInputCss = css`
  width: 100%;
  height: 44px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: 1px solid ${Theme.colors.inputs.border};
  border-radius: 0px;
  font-size: 14px;
  background: white;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${Theme.colors.inputs.placeholder};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${Theme.colors.inputs.placeholder};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${Theme.colors.inputs.placeholder};
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${Theme.colors.inputs.placeholder};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${Theme.colors.inputs.placeholder};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${Theme.colors.inputs.placeholder};
  }

  @media (max-width: 767px) {
    height: 36px;
  }
`;

export const TextInput = styled.input`
  ${textInputCss};
`;

export const inputIconStyle = css`
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  color: ${Theme.colors.icon};
  fill: ${Theme.colors.icon};
  font-size: 16px;
`;
