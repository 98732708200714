import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import LocationIcon from '../../../../shared/icons/location-icon';
import { ReactComponent as MultLocIcon } from '../../../../shared/svg/multiple-location-icon.svg';
import { ComputerIcon } from '../../../../shared/icons/computer-icon';
import { experienceTitle, experienceSubtitle } from '../../../../shared/utils/staticCssClasses';

import { Theme } from '../../../../shared/styles';

import { smallCssBreak, midCssBreak } from '../../../common_styles';

const Name = styled.h1`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin: 0 0 9px;

  @media (max-width: ${midCssBreak}) {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 4px;
  }
  @media (max-width: ${smallCssBreak}) {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Tagline = styled.div`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  min-height: 22px;
  @media (max-width: ${midCssBreak}) {
    font-size: 14px;
  }
`;

const IconStyles = css`
  fill: ${Theme.colors.icon};
  height: 18px;
  width: 18px;
  margin-right: 4px;
`;

const StyledLocationIcon = styled(LocationIcon)`
  ${IconStyles}
`;

const StyledMultipleLocationIcon = styled(MultLocIcon)`
  ${IconStyles}
`;

const StyledComputer = styled(ComputerIcon)`
  ${IconStyles}
`;

const Info = styled.div`
  width: 100%;
`;

export const Header = ({
  nameTranslation,
  taglineTranslation,
  locationInfo,
  useLocationAsSubtitle,
  isOnline,
  hasMultipleLocations
}) => {
  const { t } = useTranslation();
  let taglineContent;
  if (!useLocationAsSubtitle && taglineTranslation?.length > 0) {
    taglineContent = <Tagline className={experienceSubtitle}>{taglineTranslation}</Tagline>;
  } else {
    taglineContent = (
      <Tagline className={experienceSubtitle}>
        {isOnline ? (
          <>
            <StyledComputer />
            <Info>{t('online')}</Info>
          </>
        ) : (
          <>
            {hasMultipleLocations ? <StyledMultipleLocationIcon /> : <StyledLocationIcon />}
            <Info>{locationInfo}</Info>
          </>
        )}
      </Tagline>
    );
  }

  return (
    <>
      <Name className={experienceTitle}>{nameTranslation}</Name>
      {taglineContent}
    </>
  );
};
