// Simple wrapper around localStorage to handle this error when
// 3rd party cookies are disabled in the users browser:
// > DOMException: Failed to read the 'localStorage' property from 'Window':
// > Access is denied for this document.
export function getLocalStorage() {
  try {
    return window.localStorage;
  } catch (e) {
    console.error(e);
    return { getItem: () => null, setItem: () => {}, removeItem: () => {}, clear: () => {} };
  }
}
