/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import styled from '@emotion/styled/macro';

import { useTranslation } from 'react-i18next';

import { searchBar, searchBarInput } from '../../../shared/utils/staticCssClasses';
import SearchIcon from '../../../shared/icons/search-icon';
import CloseIcon from '../../../shared/icons/close-icon';
import { TextInput, inputIconStyle } from '../../../shared/styles';

const Container = styled.div`
  max-width: 250px;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    max-width: unset;
  }
`;

const Search = styled.div`
  position: relative;
  width: 100%;

  ${TextInput} {
    height: 100%;
    @media (max-width: 767px) {
      height: 36px;
    }
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  cursor: pointer;
`;

const SearchFilter = ({ applyFilter, clearFilter, searchQuery = '' }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(searchQuery);

  function onClear() {
    setQuery('');
    clearFilter();
  }

  return (
    <Container>
      <Search data-testid="search-bar" className={searchBar}>
        {query && query.length > 0 ? (
          <CloseIconWrapper onClick={onClear} data-testid="clear-search">
            <CloseIcon css={inputIconStyle} />
          </CloseIconWrapper>
        ) : (
          <SearchIcon css={inputIconStyle} />
        )}
        <form action="" onSubmit={e => e.preventDefault()}>
          <TextInput
            className={searchBarInput}
            data-testid="search-bar-input"
            type="search"
            name="search"
            placeholder={t('search')}
            value={query || ''}
            onChange={({ target }) => setQuery(target.value)}
            onKeyPress={e => (e.key === 'Enter' ? applyFilter(query) : null)}
          />
        </form>
      </Search>
    </Container>
  );
};

export default SearchFilter;
