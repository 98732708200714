import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const baseUrl = process.env.REACT_APP_ANYROAD_API_URL;

const Api = {
  fetchPlugin({ id }) {
    return axios({
      headers,
      method: 'get',
      url: `${baseUrl}/plugins/api/v1/plugins/${id}`
    });
  }
};

export default Api;
