// Customized ContentLoader wrapper to make it easy to use the ContentLoader library anywhere in the app

// This component wrapper makes it a little more straightforward to use the content loader,
// without having to wrangle with SVG viewbox dimension weirdness and repetitive settings

// To use, pass in some props:
// 1. Width: integer pixel value or string percentage e.g. 100px or '50%'
// 2. Height: integer pixel value or string percentage e.g. 100px or '50%'
// 3. Style: style object e.g. { marginRight: '20px' }

// Note: When using the circle shape, be sure to pass in a height/width to specify the dimensions
// of the container that will enclose the inner circle loader

// TODO: Move to a higher folder to be shared across the app
import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

class StreamlinedContentLoader extends Component {
  // Parses the props, since both string percentages and integer pixel-values are accepted
  calculateDimensions = () => {
    const { height, width } = this.props;
    let propHeight;
    let propWidth;
    let styleHeight;
    let styleWidth;

    if (typeof height === 'string' && height.includes('%')) {
      propHeight = height;
      styleHeight = height;
    } else {
      propHeight = parseInt(height); // In case formatted as string numeric
      styleHeight = `${parseInt(height)}px`;
    }

    if (typeof width === 'string' && width.includes('%')) {
      propWidth = width;
      styleWidth = width;
    } else {
      propWidth = parseInt(width); // In case formatted as string numeric
      styleWidth = `${parseInt(width)}px`;
    }

    return {
      // To be passed in as props to library component
      propHeight,
      propWidth,
      // To be passed into style variable to library component
      styleHeight,
      styleWidth
    };
  };

  render() {
    const { circle, circleSize, speed, primaryColor, secondaryColor } = this.props;
    const { propHeight, propWidth, styleHeight, styleWidth } = this.calculateDimensions();

    // Rectangular loader
    let vector = <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />;

    // Circle loader
    if (circle && circleSize) {
      vector = <circle cx={circleSize / 2} cy={circleSize / 2} r={circleSize / 2} />;
    }

    return (
      <ContentLoader
        viewBox={null}
        speed={speed}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        height={propHeight}
        width={propWidth}
        style={{ width: styleWidth, height: styleHeight, ...this.props.style }}
      >
        {vector}
      </ContentLoader>
    );
  }
}

StreamlinedContentLoader.defaultProps = {
  speed: 1.5,
  primaryColor: '#f3f3f3',
  secondaryColor: '#ecebeb',
  height: '100%',
  width: '100%',
  style: {}
};

export default StreamlinedContentLoader;
