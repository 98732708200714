import React from 'react';

export function ComputerIcon(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <path
        className="cls-1"
        d="M92.2,3.23H7.8a7.51,7.51,0,0,0-7.5,7.5V76.57a7.52,7.52,0,0,0,7.5,7.5H37.94l-.08,3.24L21,91.62a2.9,2.9,0,0,0,.72,5.72H78.41a2.82,2.82,0,0,0,.7-5.55L61.56,87.33V84.07H92.2a7.52,7.52,0,0,0,7.5-7.5V10.73A7.51,7.51,0,0,0,92.2,3.23Zm2.5,73.34a2.51,2.51,0,0,1-2.5,2.5H7.8a2.51,2.51,0,0,1-2.5-2.5V10.73a2.5,2.5,0,0,1,2.5-2.5H92.2a2.5,2.5,0,0,1,2.5,2.5Z"
      />
      <path className="cls-1" d="M15.82,67.73H84.18V20H15.82Zm5-42.76H79.18V62.73H20.82Z" />
    </svg>
  );
}
