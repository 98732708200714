import React from 'react';

export default function SearchIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      style={{ enableBackground: 'new 0 0 18 18' }}
      xmlSpace="preserve"
      width={18}
      height={18}
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M7.4,14.7C3.3,14.7,0,11.4,0,7.4S3.3,0,7.4,0s7.4,3.3,7.4,7.4S11.4,14.7,7.4,14.7z M7.4,1.1
        		c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3s6.3-2.8,6.3-6.3S10.8,1.1,7.4,1.1z"
        />
        <rect
          x="14.5"
          y="11.4"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.2287 15.0375)"
          className="st0"
          width="1.1"
          height="7.2"
        />
      </g>
    </svg>
  );
}
