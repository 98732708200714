const svSE = {
  translation: {
    apply: 'Tillämpa',
    bookNow: 'Boka nu',
    cancel: 'Avbryt',
    categories: 'Kategorier',
    category: 'Kategori',
    chinese_simplified: 'Förenklad kinesiska',
    chinese_traditional: 'Traditionell kinesiska',
    clear: 'Rensa',
    dates: 'Datum',
    datesAvailable: 'Tillgängliga datum',
    day: 'dag',
    days: 'dagar',
    dutch: 'Holländska',
    english: 'Engelsk',
    enterALocation: 'Ange en plats',
    filters: 'Filter',
    french: 'Franska',
    fromPrice: 'Från',
    fromTime: 'Från',
    german: 'Tyska',
    greek: 'Grekisk',
    guest: 'Gäst',
    guests: 'Gäster',
    hour: 'timme',
    hours: 'timmar',
    kilometers: 'kilometer',
    location: 'Plats',
    miles: 'mil',
    minutes: 'minuter',
    more: 'mer',
    next: 'Nästa',
    noFilterResults: 'Tyvärr hittade vi inga resultat för de valda filtren.',
    noResults: 'Tyvärr, vi kunde inte hitta några upplevelser.',
    noResultsDates: 'Tyvärr hittade vi inga resultat för de valda datumen.',
    noResultsFuture: 'Tyvärr, vi har inga tillgängliga upplevelser just nu.',
    noResultsGuests: 'Tyvärr hittade vi inga resultat för det valda antalet gäster.',
    noResultsWithin:
      'Tyvärr hittade vi inga resultat inom <strong>{{distance}} {{units}}</strong> från <strong>{{location}}.</strong>',
    numberOfGuests: 'Antal Gäster',
    noSearchResults: 'Tyvärr hittade vi inga resultat för "<strong>{{searchQuery}}</strong>".',
    online: 'Uppkopplad',
    previous: 'Föregående',
    search: 'Sök',
    soldOut: 'Utsåld',
    swedish: 'Svenska',
    to: 'Till',
    widenSearch: 'Prova att bredda din sökning genom att ändra eller rensa dina filter.',
    within: 'Inom'
  }
};

export default svSE;
