/*
  Experience List Component
*/

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { updatePage, updateRandomizationSeed } from '../redux/experiences/actions';
import {
  getPluginId,
  getOpenInNewTab,
  getPlugin,
  getIsGa4Enabled,
  getPluginName
} from '../redux/plugins/selectors';
import {
  getExperiences,
  getExperiencesFetching,
  getExperiencesFetchingError,
  getPage,
  getTotalPages
} from '../redux/experiences/selectors';

import { getIsGeolocating } from '../redux/filters/selectors';

import {
  experiencesList,
  experiencesListWrapper,
  pageNumbers,
  paginateButton,
  paginateContainer,
  paginator
} from '../shared/utils/staticCssClasses';
import { TruncatedGradient } from '../shared/styles';
import { fireImpressions } from '../shared/utils/gtmTracking';

import * as Paginate from './components/paginator/Paginator';
import ExperienceCard from './components/experience_card/ExperienceCard';
import { FilterBar } from './components/filter_bar/FilterBar';
import { NoResults } from './components/no_results/NoResults.tsx';

import { midCssBreak, smallCssBreak } from './common_styles';

const ExperiencesListWrapper = styled.div``;
const ExperiencesList = styled('div')`
  width: 768px;
  margin: 15px auto;
  position: relative;

  opacity: ${props => (props.fade ? '0.4' : '1')};

  @media (max-width: ${midCssBreak}) {
    width: calc(100% - 30px);
  }
  @media (max-width: ${smallCssBreak}) {
    width: 250px;
  }
`;

export const ExperienceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    useLocationAsSubtitle,
    useKmForDistance,
    filters: { locationFilter },
    gtmTracking: { trackImpressions, trackProductClick }
  } = useSelector(getPlugin);

  const pluginId = useSelector(getPluginId);
  const pluginName = useSelector(getPluginName);
  const experiences = useSelector(getExperiences);
  const experiencesFetching = useSelector(getExperiencesFetching);
  const experiencesFetchingError = useSelector(getExperiencesFetchingError);
  const isGa4Enabled = useSelector(getIsGa4Enabled);

  const isGeolocating = useSelector(getIsGeolocating);
  const page = useSelector(getPage);
  const totalPages = useSelector(getTotalPages);
  const openInNewTab = useSelector(getOpenInNewTab);

  const [fadeList, setFadeList] = useState(false);

  const isLoading = experiencesFetching || (locationFilter && isGeolocating);

  useEffect(() => {
    // Will also fetch experiences on initial load
    if (!locationFilter || (locationFilter && !isGeolocating)) {
      dispatch(updateRandomizationSeed());
    }
  }, [pluginId, locationFilter, isGeolocating, dispatch]);

  useEffect(() => {
    if (trackImpressions && experiences[0]) {
      fireImpressions(experiences, isGa4Enabled, pluginName, pluginId);
    }
  }, [experiences]);

  const renderPagination = position => {
    if (!experiences) return null;
    if (!totalPages || totalPages < 2) return null;

    const paginatorClassNames = `${paginator} pagination-bar-${position}`;

    const previousLabel = (
      <Paginate.PrevButton
        className={paginateButton}
        data-content={t('previous')}
        data-testid="previous-page"
      >
        <Paginate.LeftArrow />
      </Paginate.PrevButton>
    );

    const nextLabel = (
      <Paginate.NextButton
        className={paginateButton}
        data-content={t('next')}
        data-testid="next-page"
      >
        <Paginate.RightArrow />
      </Paginate.NextButton>
    );

    return (
      <Paginate.Paginator
        className={paginatorClassNames}
        fade={fadeList}
        marginBottom={position === 'top' ? '0px' : '100px'}
      >
        <ReactPaginate
          activeClassName="active"
          breakLabel={<span>...</span>}
          containerClassName={paginateContainer}
          forcePage={page - 1}
          marginPagesDisplayed={2}
          nextLabel={nextLabel}
          onPageChange={({ selected }) => dispatch(updatePage({ page: selected + 1 }))}
          pageCount={totalPages}
          pageLinkClassName={pageNumbers}
          pageRangeDisplayed={3}
          previousLabel={previousLabel}
        />
      </Paginate.Paginator>
    );
  };

  const renderExperienceCards = () => {
    // Loading state
    if (isLoading) {
      return [...Array(3).keys()].map(key => <ExperienceCard loading key={key} />);
    }
    if (experiencesFetchingError || (!experiencesFetching && experiences.length === 0)) {
      return <NoResults useKmForDistance={useKmForDistance} />;
    }

    // Render experience list
    return experiences.map((experience, idx) => (
      <ExperienceCard
        bookNowNewTab={openInNewTab}
        experience={experience}
        key={experience.id}
        {...(isGa4Enabled ? { index: idx + 1 } : { position: idx + 1 })}
        trackProductClick={trackProductClick}
        useLocationAsSubtitle={useLocationAsSubtitle}
        isGa4Enabled={isGa4Enabled}
        pluginId={pluginId}
        pluginName={pluginName}
      />
    ));
  };

  return (
    <>
      <FilterBar toggleFadeList={bool => setFadeList(bool)} />
      {renderPagination('top')}
      <ExperiencesListWrapper className={experiencesListWrapper}>
        <ExperiencesList
          className={experiencesList}
          loading={isLoading}
          fade={fadeList}
          data-testid="experience-list"
        >
          {renderExperienceCards()}
          {isLoading && <TruncatedGradient height={250} bottom={0} />}
        </ExperiencesList>
      </ExperiencesListWrapper>
      {renderPagination('bottom')}
    </>
  );
};
