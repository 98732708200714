/*
  A collection of shared styles for the filters
  Also used across the filters to construct the mobile popup view
*/

import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import { Theme } from '../../../shared/styles';

/* General */
export const FilterButton = styled.div`
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  min-width: 100px;
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  height: 44px;

  align-items: center;
  justify-content: center;

  border: 1px solid ${props => props.theme.brandColor};
  background: ${props => (props.applied ? props.theme.brandColor : 'inherit')};
  color: ${props => (props.applied ? 'white' : 'black')};

  @media (max-width: 767px) {
    height: 36px;
  }
`;

export const PopoverBody = styled.div`
  background: white;
  border-radius: 3px;
  padding: 25px;
`;

/* Mobile Styles ===== */
export const ThinLink = styled.div`
  color: ${props => (props.link ? props.theme.brandColor : 'black')};
  font-weight: ${props => (props.bold ? '600' : '400')};
  text-align: ${props => (props.align ? props.align : 'center')};
  min-width: 75px;

  cursor: pointer;
`;

export const OutlineButton = styled.button`
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 75px;
  text-align: center;
  font-size: 14px;
  font-family: 'proxima-nova';
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  background: unset;
  color: ${props => (props.disabled ? Theme.colors.lighterText : props.theme.brandColor)};
  border: 1px solid ${props => (props.disabled ? Theme.colors.lighterText : props.theme.brandColor)};
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: 8.5px;
  }
`;

export const HeaderBar = styled.div`
  padding: 20px;
`;

export const FooterBar = styled.div`
  border-top: 1px solid ${Theme.colors.lightBorder};
  padding: 30px;
  padding-bottom: 150px;
`;

export const modalCss = css`
  ${HeaderBar}, ${FooterBar} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${HeaderBar} {
  }
`;

export const filterContainerCss = css`
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
    border-top: 1px solid ${Theme.colors.lightBorder};
  }
`;

export const hideMobileCss = css`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const hideDesktopCss = css`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const filterHeaderCss = css`
  @media (max-width: 767px) {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
/* ===== Mobile Styles */
