/*
  Responsible for loading the blue and black header bars to be viewed by partners when browsing
  Append url param `?preview=true` to URL to render
*/

import React from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { getPreviewMode } from '../redux/router/selectors';
import { Theme, Grid, CircleAvatar } from '../shared/styles';

const HeaderCta = styled('div')`
  background: ${Theme.colors.primary};
  color: white;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  line-height: 30px;
  padding: 10px;
  a {
    color: #fff;
    margin-left: 10px;
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px;
  }
`;

const PluginPreviewHeader = () => {
  const isPreviewMode = useSelector(getPreviewMode);

  return (
    <>
      {isPreviewMode && (
        <HeaderCta>This is what other people see when they visit your Booking Site.</HeaderCta>
      )}
    </>
  );
};

export default PluginPreviewHeader;
