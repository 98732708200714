import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './locales/en-US';
import itIT from './locales/it-IT';
import frCA from './locales/fr-CA';
import deDE from './locales/de-DE';
import esES from './locales/es-ES';
import svSE from './locales/sv-SE';
import nlNL from './locales/nl-NL';
import { ENGLISH_LANGUAGE_CODE } from './constants';
import elGR from './locales/el-GR';
import zhCN from './locales/zh-CN';
import zhTW from './locales/zh-TW';

// Translation Mappings
const resources = {
  'en-US': enUS,
  'it-IT': itIT,
  'es-ES': esES,
  'de-DE': deDE,
  'fr-CA': frCA,
  'sv-SE': svSE,
  'nl-NL': nlNL,
  'el-GR': elGR,
  'zh-CN': zhCN,
  'zh-TW': zhTW
};

i18n.use(initReactI18next).init({
  resources,
  lng: ENGLISH_LANGUAGE_CODE,
  fallbackLng: ENGLISH_LANGUAGE_CODE,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
