import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { setCurrentFilter } from '../../../redux/filters/actions';
import { getSelectedCategoriesMap } from '../../../redux/filters/selectors';
import { filterButton } from '../../../shared/utils/staticCssClasses';
import Modal from '../../../shared/components/Modal';

import { Theme, Grid } from '../../../shared/styles';
import { Checkbox } from '../../../shared/components/form/Checkbox';
import { FilterButton } from './filterStyles';

import Actions from './Actions';

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 20px;
`;

const Header = styled.h1`
  padding: 20px;
  border-top: 1px solid ${Theme.colors.lightBorder};
  font-size: 16px;
`;

const GridContainer = styled.div`
  margin: 50px;

  @media (max-width: 767px) {
    margin: 0px;
    padding: 20px;
    padding-top: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const Footer = styled.div`
  padding: 30px 50px;
  box-shadow: 0px 12px 20px 0px #888;
  clip-path: inset(-20px 0px 0px 0px);
`;

const StyledActions = styled(Actions)`
  margin: 0;
`;

export const CategoryFilter = ({
  mobile = false,
  applied = false,
  buttonLabel = '',
  allCategories = [],
  applyFilter = () => {},
  clearFilter = () => {}
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCategoriesMap = useSelector(getSelectedCategoriesMap);

  const CategoryList = (
    <GridContainer data-testid="category-list">
      <StyledGrid gridTemplateColumns="1fr 1fr" gap="18px" center>
        {allCategories.map((category, idx) => (
          <Checkbox
            key={idx}
            idx={idx}
            boxColor={theme.brandColor}
            isChecked={selectedCategoriesMap[category] || false}
            onChange={checked => {
              const newCategoriesMap = { ...selectedCategoriesMap, [category]: checked };
              dispatch(setCurrentFilter({ categories: newCategoriesMap }));
            }}
          >
            {category}
          </Checkbox>
        ))}
      </StyledGrid>
    </GridContainer>
  );

  if (mobile)
    return (
      <>
        <Header>{t('category')}</Header>
        {CategoryList}
      </>
    );

  return (
    <>
      <Modal open={isModalOpen} close={() => setIsModalOpen(false)}>
        <Title>{t('categories')}</Title>
        {CategoryList}
        <Footer>
          <StyledActions
            apply={() => {
              setIsModalOpen(false);
              applyFilter(selectedCategoriesMap);
            }}
            clear={() => {
              setIsModalOpen(false);
              clearFilter();
            }}
          />
        </Footer>
      </Modal>
      <FilterButton
        onClick={() => setIsModalOpen(true)}
        className={filterButton}
        data-testid="category-filter"
        applied={applied}
      >
        {applied ? buttonLabel : t('categories')}
      </FilterButton>
    </>
  );
};
