import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';

import {
  experiencePrice,
  experiencePriceType,
  experiencePriceInfo
} from '../../../../shared/utils/staticCssClasses';

import { smallCssBreak, midCssBreak } from '../../../common_styles';

const InfoPrice = styled('span')`
  font-size: 14px;
  display: inline-block;
  text-align: center;

  div {
    display: inline-block;
  }

  strong {
    font-size: 18px;
    font-weight: 600;
    bottom: 2px;
  }

  @media (max-width: ${midCssBreak}) {
    font-size: 14px;
    strong {
      font-size: 16px;
    }
  }

  @media (max-width: ${smallCssBreak}) {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    right: 10px;
    color: white;
    text-align: right;
    display: block;
    z-index: 1;
    strong {
      display: block;
      line-height: 20px;
    }
  }
`;

export const Price = ({ zeroPriced, price }) => {
  const { t } = useTranslation();

  if (zeroPriced) return <span />;

  return (
    <InfoPrice className={experiencePrice}>
      <div className={experiencePriceType}>{`${t('fromPrice')}`}</div>{' '}
      <strong className={experiencePriceInfo}>{price}</strong>
    </InfoPrice>
  );
};
