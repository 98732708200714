/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { BrandButton } from '../../../shared/styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Actions = ({ apply, clear, hideClear, applyDisabled, className }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {hideClear ? (
        <div />
      ) : (
        <BrandButton
          onClick={clear}
          inverted
          noBorder
          css={css`
            width: unset;
          `}
          data-testid="clear-filter"
        >
          {t('clear')}
        </BrandButton>
      )}
      <BrandButton disabled={applyDisabled} onClick={apply} data-testid="apply-filter">
        {t('apply')}
      </BrandButton>
    </Container>
  );
};

export default Actions;
