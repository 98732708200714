/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import {
  ICON_AFTER_POSITION,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION
} from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';

import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../icons/calendar-icon';
import { Theme, inputIconStyle } from '../../styles';
import ArrowIcon from '../../icons/arrow-icon';

const arrowStyles = css`
  transform: rotate(270deg);
  height: 16px;
  position: unset;
`;

const datepickerStyles = theme => css`
  .DateRangePicker {
    width: 100%;
  }

  .DateRangePicker_picker {
    z-index: 2000000;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${Theme.colors.icon};
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${Theme.colors.inputs.borderAction};
  }

  .DateRangePickerInput {
    display: grid;
    align-items: center;
    width: 100%;

    border: 1px solid ${Theme.colors.inputs.borderAction};
    border-radius: 0px;

    .Datepicker {
      width: 100%;
    }
    .DateInput {
      font-family: 'proxima-nova';
      width: 100%;
      cursor: pointer;
      input {
        font-size: 14px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${theme.brandColor};
  }

  .CalendarDay {
    outline: none;
  }

  .CalendarDay:hover {
    background: ${theme.brandColor};
    color: white;
    border: 1px double ${theme.brandColor};
  }

  .CalendarDay__selected_span {
    background: ${theme.brandColor};
    color: white;
    border: 1px double ${theme.brandColor};
  }

  /* Will edit selected date or the endpoints of a range of dates */
  .CalendarDay__selected {
    background: ${theme.brandColor};
    color: white;
    border: 1px double ${theme.brandColor};
  }

  /* Will edit when hovered over. _span style also has this property */
  .CalendarDay__selected:hover {
    background: ${theme.brandColor};
    color: white;
  }

  /* Will edit when the second date (end date) in a range of dates
     is not yet selected. Edits the dates between your mouse and said date */
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${theme.brandColor};
    color: white;
    border: 1px double ${theme.brandColor};
  }

  .DateRangePickerInput_arrow {
    display: flex;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const Datepicker = ({ className, pickerProps, startDate, endDate, updateDates, mobile }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Global styles={datepickerStyles} />
      <DateRangePicker
        // Functional props =====
        startDate={startDate}
        startDateId="startDate"
        endDate={endDate}
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => updateDates({ startDate, endDate })}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        // Presentational props =====
        startDatePlaceholderText={t('fromTime')}
        endDatePlaceholderText={t('to')}
        withFullScreenPortal={mobile}
        orientation={mobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
        customInputIcon={<CalendarIcon css={inputIconStyle} />}
        inputIconPosition={ICON_AFTER_POSITION}
        customArrowIcon={<ArrowIcon css={[inputIconStyle, arrowStyles]} />}
        hideKeyboardShortcutsPanel
        minimumNights={0}
        readOnly
        numberOfMonths={mobile ? 3 : 2}
        {...pickerProps}
      />
    </Container>
  );
};

export default Datepicker;
