/*
  PluginThemeService
  Wrapper component that handles injecting partner's brand styles into all child components
*/

import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import Color from 'color';

import { baseTheme } from '../shared/styles/Theme';

import { getPluginId, getPluginTheme } from '../redux/plugins/selectors';

// Mask prevents app from being visible until partner styles loaded
// To prevent the 'flicker' as partner styles overwrite the base anyroad styles
const Mask = styled.div`
  visibility: ${props => (props.hidden ? 'hidden' : 'unset')};
`;

export const PluginThemeService = ({ children }) => {
  const pluginTheme = useSelector(getPluginTheme);
  const pluginId = useSelector(getPluginId);

  // Generate different color opacities from base 'brandColor'
  const brandOpacities = brandColor => {
    // Use the main partner brand color and convert into a few related colors
    const brand = Color(brandColor);
    return {
      brandColor75: brand.lighten(0.25).string(),
      brandColor50: brand.lighten(0.5).string(),
      brandColor25: brand.lighten(0.75).string()
    };
  };

  const theme = { ...baseTheme, ...pluginTheme };
  const extendedTheme = { ...theme, ...brandOpacities(theme.brandColor) };

  return (
    <ThemeProvider theme={extendedTheme}>
      <Mask hidden={!pluginId} id="results_container">
        {children}
      </Mask>
    </ThemeProvider>
  );
};
