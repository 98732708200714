/* A Generic Modal Component
  Desktop: Displays a white modal on a dark background
  Mobile: Full-page white modal

  Props:
  -> children: content to display
  -> open: boolean, whether the modal is open or not
  -> close: function, called when modal close triggered

  The component body also accepts classnames as a prop and should
  work with styled components
*/
/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Portal } from 'react-portal';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Theme } from '../styles';
import CloseIcon from '../icons/close-icon';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: grid;

  padding-bottom: 60px;
  overflow-y: auto;
  overflow-x: hidden;

  background: rgba(0, 0, 0, 0.5);

  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const ModalBody = styled.div`
  position: relative;

  min-height: initial;
  width: 600px;
  max-width: 90vw;

  padding: 0px;
  margin: 240px auto auto;

  background: white;

  @media (max-width: 767px) {
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin: auto;
    overflow: ${props => (props.open ? 'auto' : 'unset')};
  }

  /* General Transition rules */
  -webkit-transition: all ${props => (props.open ? '300ms' : '250ms')}
    cubic-bezier(0.465, 0.183, 0.153, 0.946);
  -moz-transition: all ${props => (props.open ? '300ms' : '250ms')}
    cubic-bezier(0.465, 0.183, 0.153, 0.946);
  transition: all ${props => (props.open ? '300ms' : '250ms')}
    cubic-bezier(0.465, 0.183, 0.153, 0.946);

  /* Scale in */
  transform: ${props => (props.open ? 'translateY(0)' : 'translateY(20%)')};
  -webkit-transform: ${props => (props.open ? 'translateY(0)' : 'translateY(20%)')};
  -moz-transform: ${props => (props.open ? 'translateY(0)' : 'translateY(20%)')};
  -ms-transform: ${props => (props.open ? 'translateY(0)' : 'translateY(20%)')};

  /* Fade in */
  opacity: ${props => (props.open ? 1 : 0)};

  will-change: transform, opacity;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: -20px;
  right: -20px;
  height: 40px;
  width: 40px;

  border: 1px solid white;
  border-radius: 50%;
  background: ${Theme.colors.black};
  color: white;
  font-size: 16px;
  cursor: pointer;

  svg {
    fill: white;
  }

  /* Mobile close button is inside of the full-page modal */
  @media (max-width: 767px) {
    top: 20px;
    right: 20px;
    background: white;
    color: #999;
  }
`;

class Modal extends Component {
  modalBody = null;

  componentDidMount() {
    this.modalBody = document.querySelector('#modalWrapper');
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    // Enable/disable body scroll lock as necessary
    if (open && !prevProps.open) {
      disableBodyScroll(this.modalBody);
    } else if (!open && prevProps.open) {
      enableBodyScroll(this.modalBody);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { open, close, children, hideClose, className, css, slideUp } = this.props;

    return (
      <Portal>
        <Wrapper id="modalWrapper" data-testid="filter-modal" open={open} onClick={close}>
          <ModalBody
            slideUp={slideUp}
            className={className}
            css={css}
            open={open}
            onClick={e => e.stopPropagation()}
          >
            {hideClose ? null : (
              <CloseButton onClick={close}>
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ModalBody>
        </Wrapper>
      </Portal>
    );
  }
}

export default Modal;
