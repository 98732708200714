const esES = {
  translation: {
    apply: 'Aplicar',
    bookNow: 'Reservar',
    cancel: 'Cancelar',
    categories: 'Categorías',
    category: 'Categoría',
    chinese_simplified: 'Chino simplificado',
    chinese_traditional: 'Chino tradicional',
    clear: 'Borrar',
    dates: 'Fechas',
    datesAvailable: 'Fechas disponibles',
    day: 'día',
    days: 'dias',
    dutch: 'Holandés',
    english: 'Inglés',
    enterALocation: 'Ingresar una ubicación',
    enterALocation: 'Introduce una ubicación',
    filters: 'Filtros',
    french: 'Francés',
    fromPrice: 'Desde',
    fromTime: 'Del',
    fromTime: 'Desde',
    german: 'Alemán',
    greek: 'Griego',
    guest: 'Persona',
    guests: 'Personas',
    hour: 'hora',
    hours: 'horas',
    kilometers: 'kilómetros',
    location: 'Ubicación',
    miles: 'millas',
    minutes: 'minutos',
    more: 'más',
    next: 'Siguiente',
    noFilterResults:
      'Lo sentimos, no hemos encontrado ningún resultado para los filtros seleccionados.',
    noResults: 'Lo sentimos, no hemos podido encontrar ninguna experiencia.',
    noResultsDates:
      'Lo sentimos, no hemos encontrado ningún resultado para las fechas seleccionadas.',
    noResultsFuture: 'Lo sentimos, no tenemos experiencias disponibles en este momento.',
    noResultsGuests:
      'Lo sentimos, no hemos encontrado ningún resultado para el número de huéspedes seleccionado.',
    noResultsWithin:
      'Lo sentimos, no hemos encontrado ningún resultado a <strong>{{distance}} {{units}}</strong> de <strong>{{location}}.</strong>',
    noSearchResults:
      'Desafortunadamente, no encontramos ningún resultado para "<strong>{{searchQuery}}</strong>".',
    numberOfGuests: 'Número de invitados',
    online: 'En línea',
    previous: 'Anterior',
    search: 'Buscar',
    soldOut: 'Agotado',
    swedish: 'Sueco',
    to: 'Al',
    widenSearch: 'Intente ampliar su búsqueda cambiando o borrando sus filtros.',
    within: 'Dentro de'
  }
};

export default esES;
