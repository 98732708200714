// Plugin Reducer
// Loads top-level config and theme information for a plugin
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPlugin,
  fetchPluginSuccess,
  fetchPluginFailure,
  changeI18n,
  updatePluginFilters
} from './actions';

const initialState = {
  plugin: {},
  pluginFetching: false,
  pluginFetchingError: false,
  i18n: null
};

export const reducer = createReducer(initialState, {
  [fetchPlugin]: state => {
    state.pluginFetching = true;
    state.pluginFetchingError = false;
  },
  [fetchPluginSuccess]: (state, { payload: { plugin } }) => {
    state.plugin = plugin;
    state.pluginFetching = false;
    state.pluginFetchingError = false;
  },
  [fetchPluginFailure]: state => {
    state.plugin = {};
    state.pluginFetching = false;
    state.pluginFetchingError = true;
  },
  [changeI18n]: (state, { payload: { i18n } }) => {
    state.i18n = i18n;
  },
  [updatePluginFilters]: (state, { payload: { categories } }) => {
    state.plugin.filters.categories = categories;
  }
});
