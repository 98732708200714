const Theme = {
  mobile: {
    mobileBreakpointWidth: 700,
    tinyMobileBreakpointWidth: 360
  },
  colors: {
    primary: '#3D57FF',
    black: '#333',
    lightBlue: '#e6f4f7',
    selectionBlue: '#ebf5ff',
    green: '#8EC63F',
    gold: '#A08E5C',
    orange: '#fb8e3b',
    red: '#e73c3e',
    lightLine: '#e6e6e6',
    divider: '#eee',
    lightBackground: '#f2f2f2',
    mediumBackground: '#ddd',
    lightText: '#999',
    lighterText: '#6e6e6e',
    mediumText: '#666',
    darkText: '#333',
    hoverText: '#888',
    lightBorder: '#cccccc',
    icon: '#8c8c8c',
    inputs: {
      border: '#cecece',
      borderAction: '#949494',
      placeholder: '#757575'
    }
  }
};

// Base theme used for partner-branded elements
export const baseTheme = {
  brandColor: Theme.colors.primary
};

export default Theme;
