/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';

import { useTranslation } from 'react-i18next';

import { filterButton } from '../../../shared/utils/staticCssClasses';
import Popover from '../../../shared/components/Popover';
import Actions from './Actions';
import MinusIcon from '../../../shared/icons/minus-icon';
import PlusIcon from '../../../shared/icons/plus-icon';
import { Theme } from '../../../shared/styles';

import { PopoverBody, FilterButton, filterContainerCss, filterHeaderCss } from './filterStyles';

const Container = styled.div`
  min-width: 250px;
  ${filterContainerCss};
`;

const Toggler = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 1px 6px;
  cursor: pointer;
  background: unset;

  font-size: 30px;
  border: 1px solid ${Theme.colors.inputs.borderAction};
  fill: ${props => props.theme.brandColor};
  opacity: ${props => (props.disabled ? '0.3' : '1')};

  /* Prevent text highlighting for better tap ux */
  user-select: none;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
`;

const Heading = styled.div``;
const Selector = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  text-align: left;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    ${Heading}, ${Toggler} {
      width: 100%;
    }

    ${ToggleButton} {
      width: 36px;
      height: 36px;
    }
  }
`;

const GuestFilter = ({
  mobile,
  applied,
  popoverProps,
  buttonLabel,
  applyFilter,
  clearFilter,
  guestCount,
  cacheKey
}) => {
  const { t } = useTranslation();
  const [iGuestCount, setIGuestCount] = useState(Number(guestCount) || 1);

  useEffect(() => {
    setIGuestCount(Number(guestCount) || 1);
  }, [guestCount, cacheKey]);

  const updateInternalGuestCount = newGuestCount => {
    setIGuestCount(Number(newGuestCount));
    if (mobile) {
      applyFilter(Number(newGuestCount));
    }
  };

  const filter = (
    <Container data-testid="guest-filter-widget">
      <Selector>
        <Heading css={filterHeaderCss}>{mobile ? t('numberOfGuests') : t('guests')}</Heading>
        <Toggler>
          <ToggleButton
            disabled={iGuestCount < 2}
            onClick={e => {
              updateInternalGuestCount(iGuestCount - 1);
              e.preventDefault();
            }}
            data-testid="decrease-guest"
          >
            <MinusIcon />
          </ToggleButton>
          {iGuestCount}
          <ToggleButton
            onClick={() => updateInternalGuestCount(iGuestCount + 1)}
            data-testid="increase-guest"
          >
            <PlusIcon />
          </ToggleButton>
        </Toggler>
      </Selector>

      {!mobile && <Actions apply={() => applyFilter(Number(iGuestCount))} clear={clearFilter} />}
    </Container>
  );

  if (mobile) return filter;
  return (
    <Popover {...popoverProps} content={<PopoverBody>{filter}</PopoverBody>}>
      <FilterButton className={filterButton} data-testid="guest-filter" applied={applied}>
        {applied ? buttonLabel : t('guests')}
      </FilterButton>
    </Popover>
  );
};

export default GuestFilter;
