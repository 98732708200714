import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';

import ClockIcon from '../../../../shared/icons/clock-icon';
import { Theme } from '../../../../shared/styles';
import {
  experienceDuration,
  experienceDurationIcon
} from '../../../../shared/utils/staticCssClasses';

import { smallCssBreak } from '../../../common_styles';

const InfoTime = styled.span`
  font-weight: 300;
  display: flex;
  align-self: center;

  height: 20px;
  align-items: center;
  svg {
    margin-right: 10px;
    fill: ${Theme.colors.icon};
  }
  @media (max-width: ${smallCssBreak}) {
    font-size: 14px;
    margin-left: 10px;
  }
`;

export const Time = ({ length, lengthType }) => {
  const { t } = useTranslation();

  if (length == null || lengthType == null) {
    return null;
  }

  let formattedLength;

  if (lengthType == 'hours') {
    if (length < 1) {
      formattedLength = `${Math.round(length * 60)} ${t('minutes')}`;
    } else if (length === 1) {
      formattedLength = `1 ${t('hour')}`;
    } else {
      formattedLength = `${length} ${t('hours')}`;
    }
  } else if (lengthType == 'days') {
    if (length < 1) {
      formattedLength = `${Math.round(length * 24)} ${t('hours')}`;
    } else if (length === 1) {
      formattedLength = `1 ${t('day')}`;
    } else {
      formattedLength = `${length} ${t('days')}`;
    }
  }

  return (
    <InfoTime className={experienceDuration}>
      <ClockIcon className={experienceDurationIcon} />
      {formattedLength}
    </InfoTime>
  );
};
